.property-details-hero-section {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 16px 32px 16px;

  .property-details-hero-container {
    width: 100%;
    max-width: 836px;
  }

  .property-details-hero-title {
    border-bottom: 1px solid #eaecf0;
    padding-top: 16px;
    h1 {
      font-size: 24px;
      line-height: 32px;
      margin: 0;
    }
    .property-details-hero-subtitle {
      margin-top: 5px;
      span {
        cursor: pointer;
      }
    }
  }

  .property-details-hero-images-carousel div {
    height: 400px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    overflow: hidden;
  }

  .property-details-hero-images-wrapper {
    display: none;
    @media screen and (min-width: 900px) {
      display: flex;
      align-items: center;
      gap: 16px;
      margin-top: 24px;

      .main-image-wrapper {
        flex: 1 1;
        .main-image {
          border-radius: 12px;
          width: 410px;
          height: 396px;
          background-position: center center;
          background-size: cover;
          box-shadow: 0px 1px 2px 0px #1018280f;
          box-shadow: 0px 1px 3px 0px #1018281a;
        }
      }

      .grid-images-wrapper {
        position: relative;
        flex: 1 1;
        display: flex;
        align-items: center;
        gap: 16px;
        flex-wrap: wrap;
        .grid-image {
          width: 197px;
          height: 190px;
          border-radius: 12px;
          background-position: center center;
          background-size: cover;
          box-shadow: 0px 1px 2px 0px #1018280f;
          box-shadow: 0px 1px 3px 0px #1018281a;
        }
        .show-all-photos {
          position: absolute;
        }
      }
      .MuiButton-root:hover {
        background-color: #fff;
      }
    }
  }
}

.info-section {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 16px 32px 16px;

  .info-section-container {
    width: 100%;
    max-width: 836px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 32px;
    flex-wrap: wrap;
    @media screen and (min-width: 900px) {
      flex-direction: row;
    }
    p {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
      margin: 0;
    }
    .main-price {
      font-size: 24px;
      font-weight: 600;
      line-height: 32px;
      text-align: left;
      margin-bottom: 16px;
    }

    .crypto-price-wrapper {
      margin-top: 4px;
      display: flex;
      align-items: flex-start;
      gap: 8px;
      margin-bottom: 8px;
      flex-direction: column;
      @media screen and (min-width: 768px) {
        flex-direction: row;
        align-items: center;
        gap: 24px;
      }
      .crypto-price {
        display: flex;
        align-items: center;
        img {
          margin-right: 2px;
        }
      }
    }

    .property-location {
      p {
        display: flex;
        align-items: center;
        color: #262632;
        img {
          margin-right: 4px;
        }
      }
    }

    .info-cta {
      width: 100%;
      max-width: 320px;
      p {
        font-size: 14px;
        margin: 0;
      }
    }

    .info-cta-wrapper {
      display: flex;
      flex-direction: column;
      border: 1px solid #eaecf0;
      padding: 32px 24px;
      border-radius: 12px;
      gap: 16px;
      .MuiButton-root {
        text-transform: none;
        font-weight: 600;
        line-height: 24px;
      }

      .MuiButton-outlinedPrimary {
        background-color: #0ba5ec;
        color: #fff;
        border: 1px solid #0ba5ec;
      }
    }
  }
}

.what-place-offers-section {
  padding: 0 16px;
  h3 {
    margin: 0;
    margin-bottom: 8px;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    text-align: left;
    color: #101828;
  }
  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    margin-top: 0;
    margin-bottom: 16px;
  }

  .key-figures-row {
    display: flex;
    flex-direction: column;
    gap: 16px;
    @media screen and (min-width: 768px) {
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
    }
  }
  .floor-plan-img {
    max-width: 200px;
  }
  .kye-figures-list {
    display: flex;
    flex-wrap: wrap;
    @media screen and (min-width: 768px) {
      max-width: 66%;
    }
    .kye-figure {
      margin-top: 16px;
      width: 50%;
      display: flex;
      align-items: center;
      gap: 8px;
      flex-wrap: wrap;
      span {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}

.about-section {
  margin-top: 32px;
  padding: 0 16px;
  h3 {
    margin: 0;
    margin-bottom: 24px;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    text-align: left;
    color: #101828;
  }
  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    margin-top: 0;
    margin-bottom: 16px;
  }
}

.partners-section {
  margin-top: 32px;
  padding: 0 16px;
  h3 {
    margin: 0;
    margin-bottom: 24px;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    text-align: left;
    color: #101828;
  }
  h4 {
    margin: 0;
    margin-bottom: 8px;
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    text-align: left;
  }
  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    margin-top: 0;
    margin-bottom: 16px;
  }
  .partner {
    margin-top: 24px;
    h4 {
      span {
        font-size: 14px;
      }
    }
  }
}

.location-section {
  margin-top: 32px;
  padding: 0 16px;
  h3 {
    margin: 0;
    margin-bottom: 24px;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    text-align: left;
    color: #101828;
  }
  h4 {
    margin: 0;
    margin-bottom: 8px;
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    text-align: left;
  }
  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    margin-top: 0;
    margin-bottom: 16px;
  }
  img {
    width: 100%;
    margin-bottom: 10px;
    border-radius: 12px;
  }
}
