@import "./../../assets/css/theme";
@import "styles";

.property-hero-container {
  background: $blue-primary-color;
  position: relative;
  padding-top: 50px;
  .property-hero-slider-image {
    height: 400px;
    width: 90%;
    margin: 0 auto;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .react-multi-carousel-list {
    position: relative;
    width: 90%;
    .react-multiple-carousel__arrow {
      @include themify-no-context {
        opacity: 0.8;
      }
      border-radius: 0;
    }
    .react-multiple-carousel__arrow:hover {
      opacity: 1;
    }
    .react-multiple-carousel__arrow--right {
      right: 0 !important;
    }
    .react-multiple-carousel__arrow--left {
      left: 0 !important;
    }
  }
  .property-hero-info-container {
    position: relative;
    padding-left: 100px;
    padding-bottom: 30px;
    h1 {
      margin-bottom: 20px;
      max-width: 550px;
      font-weight: 700;
      font-size: 40px;
      line-height: 45px;
      color: #333333;
    }
    a {
      font-weight: 500;
      font-size: 20px;
      line-height: 26px;
      color: #333333 !important;
      position: relative;
      span {
        width: 25px;
        height: 30px;
        top: 0;
        background-size: 100% 100%;
        display: inline-block;
        z-index: 1343123;
        position: absolute;
        left: -30px;
      }
    }
  }
}
@media screen and (max-width: 617px) {
  .property-hero-info-container {
    padding-left: 40px !important;
  }
}

.property-hero-container {
  img.ribbon-img {
    position: absolute;
    z-index: 2;
    left: 6.2%;
    width: 150px;
  }
}

.MuiContainer-root {
  position: relative;
}

@media screen and (max-width: 768px) {
  .property-hero-container {
    img.ribbon-img {
      left: 7.5%;
    }
  }
}
