@import "./../../assets/css/theme";

.property-card-box {
  // border-radius: 0 !important;
  width: 100%;
  margin: 0 auto;
  position: relative;
  box-shadow: none !important;
  border: 1px solid #D0D5DD !important;
  border-radius: 12px !important;
  box-shadow: 0px 1px 2px 0px #1018280F !important;;
  box-shadow: 0px 1px 3px 0px #1018281A !important;;

  height: 100%;

  @include themify-no-context {
    background-color: $background;
    color: $text-color;
  }

  .property-images {
    img {
      width: 100%;
      max-height: 200px;
      border-radius: 0 0 5px 5px;
    }
    .react-multiple-carousel__arrow {
      @include themify-no-context {
        background-color: #fff;
        opacity: 0.6;
        min-height: 36px;
        min-width: 36px;
      }
      // border-radius: 0;
      &::before {
        color: #101828;
        font-size: 16px;
      } 
    }
    .react-multiple-carousel__arrow:hover {
      opacity: 1;
    }
    .react-multiple-carousel__arrow--right {
      right: 0 !important;
    }
    .react-multiple-carousel__arrow--left {
      left: 0 !important;
    }
  }

  .property-content {
    padding: 0 !important;
    padding: 0 1rem 1rem 1rem !important;
    margin-top: 20px;
    .property-details {
      text-align: center;
      @include themify-no-context {
        background: $background;
      }
      padding: 5px 20px;

      p {
        margin: 0;
      }

      .property-name {
        font-size: 22px;
        @include themify-no-context {
          color: $button-text;
        }
        margin-bottom: 5px;
      }

      .pledged-info {
        font-size: 14px;
        @include themify-no-context {
          color: $button-text;
        }
        padding: 10px 15px;
      }
    }

    .property-values {
      width: 100%;
      padding: 10px 15px;

      .txt-title {
        text-align: left;
        @include themify-no-context {
          color: $react-table-header-text;
        }
        font-size: 13px;
      }

      .txt-value {
        text-align: right;
        @include themify-no-context {
          color: $button-text;
        }
        font-size: 16px;
      }
    }
  }
}

.property-location {
  font-size: 14px;
  @include themify-no-context {
    color: $text-secondary;
  }
  // margin: 5px 0 0 0;
  // margin: 0 auto;
  // padding: 5px 0;

  // .MuiSvgIcon-root {
  //   margin-top: -2px;
  //   width: 22px;
  //   height: 22px;
  // }

  // span {
  //   position: relative;
  //   top: -4px;
  //   font-weight: 400;
  //   font-size: 15px;
  //   line-height: 18px;
  //   @include themify-no-context {
  //     color: $text-color;
  //   }
  // }

  .badge-new {
    background-color: $secondary-color;
    @include themify-no-context {
      color: $background;
    }
    padding: 3px 10px;
    font-weight: 600;
    border-radius: 8px;
    float: right;
    top: 0;
  }
}

.property-location-card-container {
  // display: flex;
  // height: 40px;
  margin-top: 8px;
  overflow: hidden;
  display: flex;
  gap: 4px;
  img {
    height: 20px;
  }
  .property-location {
    margin: 0;
    padding: 0;
    color: #475467;
  }
  // .MuiSvgIcon-root {
  //   @include themify-no-context {
  //     color: $primary;
  //     position: relative;
  //     left: -5px;
  //   }
  // }
}

.property-financial-block {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  // div {
  //   width: calc(100% / 3);
  //   max-width: calc(100% / 3);

  //   &:last-child {
  //     border-right: none;
  //   }
  // }

  .txt-title {
    font-size: 12px;
    @include themify-no-context {
      color: $dashboard-widget-title;
    }
    margin: 5px 0 0;
    font-weight: 300;
  }

  .txt-value {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 26px;
    @include themify-no-context {
      color: $text-color;
    }
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0;
    span {
      @include themify-no-context {
        color: $primary;
      }
    }
  }
  .main-price {
    font-weight: 600;
    font-size: 24px;
    color: #101828;
    span {
      color: #101828 !important;
    }
  }
  .second-price {
    color: #475467;
    font-size: 16px;
    font-weight: 400;
    span {
      color: #475467 !important;
    }
  }
  .crypto-price-wrapper {
    margin-top: 8px;
    display: flex;
    align-items: center;
    gap: 8px;
    .txt-value {
      display: flex;
      align-items: center;
      gap: 4px;
      span {
        color: #475467;
        display: flex;
        align-items: center;
      }
    }
  }
}

.property-card-box.grid-view {
  .property-images {
    position: relative;
  }

  .property-image {
    width: 100%;
    height: 200px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .property-details {
    margin-top: 8px;
    width: 100%;
    max-width: 100%;
    z-index: 1;
    bottom: 0;
    padding: 0;
    text-align: left;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #475467;
    .property-name {
      color: $primary-color;
      font-size: 14px;
      margin: 0;
    }
  }
}

.list-view.secondary-type {
  .property-details {
    margin-top: 35px;
  }
}

.property-image-count {
  position: absolute;
  top: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 5px 10px;
  border-radius: 0 0 0 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px !important;
  gap: 5px;
  line-height: 1;

  svg {
    width: 18px;
    height: 18px;
  }
}

@media screen and (max-width: 768px) {
  .list-view.secondary-type {
    .property-details {
      margin-top: 10px;
    }
  }
}

.list-view {
  max-width: 800px;
  width: 100%;
  @include themify-no-context {
    border: 1px solid $background-gray;
  }
  margin: 20px 0;

  .property-images {
    width: 50%;
    float: left;

    img {
      height: 240px;
      max-height: 240px;
    }
  }

  .property-content {
    width: 50%;
    float: left;

    .property-details {
      .property-location {
        padding: 5px 15px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .property-images {
      width: auto;
      float: none;
    }

    .property-content {
      width: auto;
      float: none;
    }
  }
}

.uploaded-by {
  color: $primary-color;
  & span {
    color: $blue-primary-color;
    cursor: pointer;
  }
}

.carousel-wrapper {
  img.ribbon-img {
    position: absolute;
    z-index: 2;
    width: 100px;
  }
}
