@import "./../../assets/css/theme";

.navbar {
  box-shadow: none !important;
  @include themify-no-context {
    border-bottom: 1px solid $caret;
  }

  .nav-logo {
    cursor: pointer;
    padding: 3px 0px;

    img {
      width: 200px;
    }
  }
}

.isSticky .MuiAppBar-positionStatic.navbar {
  background-color: #FFF !important;
  border: none !important;
  box-shadow: 0 -6px 10px 5px rgba(0,0,0,0.2) !important;
}

.btn-menu-toggle {
  padding: 0 5px 0 10px !important;
}

.header-link-container {
  padding-top: 56px;
  padding-left: 30px;
}

// responsive

.nav-logo-mobile {
  display: none;
}
.nav-logo-desktop {
  display: block;
}


.header-links {
  font-weight: 600;
  text-transform: capitalize;

  @include themify-no-context {
    background-color: transparent !important;
  }
  display: inline-flex;
  text-align: center;
  top: 0px;
  padding-top: 14px !important;

  li {
    cursor: pointer;
    width: auto;
    min-height: auto;
    padding: 4px 32px 12px;

    a {
      @include themify-no-context {
        color: #101828 !important;
      }
      font-weight: 600;
      cursor: pointer;
      text-decoration: none;
    }
  }

  .text-username {
    position: relative;
    left: 5px;
    top: 0;
    font-size: 14px;
    @include themify-no-context {
      color: #101828 !important;
    }
  }

  .custom-dropdown {
    top: -14px !important;
    z-index: 99999;
    #menu-list-grow {
      ul {
        // @include themify-no-context {
        //   background: $primary;
        // }
        li {
          padding: 0;
          a {
            padding: 4px 15px;
            font-size: 14px;
            display: block;
            width: 100%;
          }
        }
      }
    }
  }

  & svg {
    position: absolute;
  }
}


header.navbar .btn-menu-toggle svg {
  fill: #fff;
}



@media screen and (max-width: 959px) {
  .header-link-container {
    padding-left: 0;
    width: 100%;
    // width: 280px;
    // background-color: #2BAAEA !important;

    .header-links li a {
      text-transform: none;
      color: #101828 !important;
    }
  }

  .header-links {
    display: flex;
    flex-direction: column;
    width: 100%;
    left: 0;

    > *:hover {
      cursor: pointer;
      @include themify-no-context {
        background-color: rgba(0, 0, 0, 0.04);
      }
    }

    > li {
      padding: 0;
      > * {
        span,
        a {
          display: block;
        }

        a {
          padding: 16px 32px;
        }
      }
    }

    .user-avtar {
      padding: 8px 32px;
    }
  }
}

.MuiListItem-root {
  width: auto !important;
  padding-top: 0 !important;
}

.MuiList-root .header-sublinks ul {
  background-color: #fff;
  color: #101828;
}

.MuiList-root .header-sublinks ul li {
  padding-top: 10px !important;
}

.header-sublinks-container {
  z-index: 1;
  top: 20px !important;
  .MuiListItem-button {
    font-weight: 600;
    text-transform: capitalize;
  }
}

@media screen and (max-width: 959px) {
  #composition-button {
    padding: 16px 32px;
  }
  .header-sublinks-container {
    top: 0px !important;
  }
}

.app-navbar-container.noSticky header {
  top: 0;
  background-color: #fff;
}

.MuiToolbar-regular {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  & .btn-menu-toggle .MuiSvgIcon-root {
    height: 40px;
    width: 40px;
    margin-top: 5px;
    margin-left: 10px;
    & path {
      color: #4abbf3;
    }
  }
}




@media screen and (max-width: 959px) {
  .MuiDrawer-paperAnchorLeft  {
    // background-color: #2BAAEA !important;
  }

  .MuiToolbar-root {
    align-items: center;
    justify-content: flex-start !important;
  }

  .navbar {
    .nav-logo-mobile {
      display: block;
      img {
        width: 150px;
      }
    }

    .btn-menu-toggle {
      display: block;
      width: 45px;
      height: 45px;
      position: absolute;
      right: 16px;
    }
  }

  .nav-logo-desktop {
    display: none;
  }

  .header-links > li > * a {
    padding: 10px 32px !important;
  }

  .header-links .user-avtar {
    margin-top: 20px;
    padding-top: 20px !important;
    border-top: solid 1px #fff;
  }
}

.custom-navigation {
  .app-bar {
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}