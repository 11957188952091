@import "./assets/css/theme.scss";

html,
body {
  @include themify-no-context {
    background-color: $background;
  }
  height: 100%;
}

.fixed {
  position: sticky;
  top: 0;
  z-index: 99999;
  @include themify-no-context {
    background-color: $background;
  }
}

.app-root {
  min-height: calc(100vh - 70px);

  @include themify-no-context {
    background-color: $background;
    color: $text-color;
  }
}

.MuiPopover-root {
  z-index: 130000 !important;
}

.homeNoStickedHeader.home-page-nav {
  background-color: transparent !important;

  .navbar {
    background-color: transparent !important;
    border-bottom: none !important;
  }
}

.modal-delete,
.modal-confirm {
  padding: 30px 25px !important;

  .modal-delete-content,
  .modal-confirm-content {
    margin: 10px 0;
    padding: 0 5px;
  }
  .bttn-group {
    width: 100%;
    margin-top: 5px;
  }
  .bttn {
    width: 50%;
    background-color: rgb(255, 255, 255);
  }
  .bttn-delete {
    color: rgb(255, 0, 0);
  }
  .bttn-confirm {
    &:hover {
      color: #27c6a8;
    }
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  @include themify-no-context {
    -webkit-text-fill-color: $text-color;
    -webkit-box-shadow: 0 0 0 30px $dashboard-asset inset !important;
  }
}

ol {
  font-size: 0.95rem;
  text-align: justify;
  text-justify: inter-word;
}
ol li {
  list-style: lower-alpha;
  margin-top: 1rem;
  margin-bottom: 1 rem;
}

.not-found {
  min-height: calc(100vh - 200px);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 8em;
  opacity: 0.2;

  animation: fadeIn 3s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0.2;
  }
}

.app-root .page-box {
  margin-top: 40px;
}

.responsive-img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
}

.pages-section-header {
  background-color: #6abeff;
  color: #fff !important;
  padding: 10px;
}

.chat-gpt-title {
  margin-bottom: 10px !important;
  font-weight: bold !important;
  font-size: 26px !important;
}

.chat-gpt-subtitle {
  // padding: 0 50px;
}

.chat-gpt-text {
  background-color: #d0eaff;
  position: relative;
  bottom: 30px;
  z-index: 1;
  text-align: center;
  padding: 15px 0 5px;
}

.lottie-gpt-container {
  position: relative;
  z-index: 2;
}

.chat-gpt-p {
  align-content: space-evenly;
  font-style: italic;
}

.loans-title {
  margin-bottom: 0 !important;
}

.bullet-list {
  list-style-type: disc;
  & li {
    display: list-item;
    list-style: inside;
  }
}
