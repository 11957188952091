.share-button {
  display: inline-block;

  .share-button__bnt {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 15px;
    padding: 5px 15px;
    line-height: 24px;
    border: solid 1px #e0e0e0;
    border-radius: 8px;
    cursor: pointer;
    // prevent text selection
    user-select: none;

    &:hover {
      background-color: #f5f5f5;
    }
  }
}

.share-button__item {
  cursor: pointer;
  user-select: none;
  .MuiListItemText-primary {
    font-size: 14px;
    font-weight: 600;
  }
  .MuiListItemText-secondary {
    font-size: 13px;
  }

  &:hover {
    background-color: #f5f5f5;
  }
}
