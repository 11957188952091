@import "./../../assets/css/theme";

.property-map-section {
  margin-top: 0;
  position: fixed;
  top: 180px;
  bottom: 0px;
  right: 15px;
  left: 15px;
  border-radius: 12px;
  overflow: hidden;

  @media (min-width: 600px) {
    top: 195px;
    bottom: 0px;
    right: 24px;
    left: auto;
    width: calc(50% - 24px);
    max-width: calc(50% - 24px);
  }

  @media (min-width: 788px) {
    top: 155px;
    bottom: 0px;
    right: 24px;
    left: auto;
    width: calc(50% - 24px);
    max-width: calc(50% - 24px);
  }

  @media (min-width: 960px) {
    top: 180px;
    bottom: 0px;
    right: 32px;
    left: auto;
    width: calc(33.333333% - 24px);
    max-width: calc(33.333333% - 24px);
  }

  .marker {
    position: absolute;
    transform: translate(-50%, -100%);
    svg {
      width: 45px !important;
      height: 45px !important;
      fill: $secondary-color;
    }
    .info-window {
      display: "block";
      position: absolute;
      z-index: 1000000;
      background-color: #27c6a8;
      width: "auto";
      visibility: hidden;
      min-width: 150px;
      left: -58px;
      top: 52px;
      border-radius: 5px;
      box-shadow: 11px 15px 25px -8px rgba(0, 0, 0, 0.75);
      color: "#FFF";
      font-size: 14px;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      -webkit-transition: 0.2s ease;
      transition: 0.2s ease;
      -webkit-transition-delay: 0;
      transition-delay: 0;

      &::before {
        content: "";
        display: block;
        width: 0;
        height: 0;
        position: absolute;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-right: 10px solid #27c6a8;
        left: 50%;
        top: -14px;
        transform: rotate(90deg);
      }

      .cover-image-container {
        width: 150px;
        height: auto;

        .image {
          background-position: center;
          background-size: cover;
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
          border-bottom-left-radius: 0px;
          border-bottom-right-radius: 0px;
          height: 10vh;
          max-height: 70px;
          background-color: #000;
          background-repeat: no-repeat;
        }
      }

      .property-title {
        text-align: center;
        margin-top: 5px;
        padding: 5px;
        padding-bottom: 0px;
        color: #fff;
        justify-self: center;
      }

      .property-address,
      .property-price {
        text-align: center;
        margin: 0px;
        color: #fff;
        justify-self: center;

        span {
          font-size: 12px;
        }
      }

      .property-price {
        padding-bottom: 5px;
      }
    }
  }

  .marker:hover {
    .info-window {
      visibility: visible;
      -webkit-transform: translateY(-5px);
      -ms-transform: translateY(-5px);
      transform: translateY(-5px);
      -webkit-transition-delay: 20ms;
      transition-delay: 20ms;
    }
  }

  .cluster-marker {
    width: 30px;
    height: 30px;
    line-height: 30px;
    border-radius: 50%;
    text-align: center;
    font-size: 16px;
    color: #fff;
    background-color: #e72700;
  }
}

.dashboard-content {
  .property-map-section {
    position: unset;
    max-width: 100%;
    width: 100%;
  }
}
