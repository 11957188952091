
$primary-color: #000 !default;
$blue-primary-color: #2BAAEA !default;
$light-blue-primary-color: #ECF9FF !default;
$secondary-color: #27c6a8 !default;
$button-color: $primary-color !default;
$button-text-color: #FFF !default;
$black: #000 !default;
$white-text: #fff;
$orange: #d85912 !default;
$grey: #a0a0a0 !default;
$white-color: #fff !default;

$themes: (
    'light': (
        // Colors
        'primary': #2BAAEA,
        'secondary': #27c6a8,
        'background': #fff,
        'background-gray': #e0e0e0,
        'text-color': #262632,
        'text-secondary': #27c6a8,
        'text-profit': #6dd130,
        'text-loss': #172228,
        'button-background': #fff,
        'button-text': #262632,
        'caret': #c4c4c4,
        'filter-border': #77787a,
        'range-border': #5d5d5d,
        'progress-success-background': #6dd130,
        'progress-info-background': #ffac2c,
        'progress-danger-background': #fe4c96,
        'table-text': #212529,
        'table-background': #fff,
        'table-border': #f6f7f9,
        'table-search-text': #778ba3,
        'table-right-text': #778ba3,
        'table-currency-text': #262632,
        'table-row-inactive': #ffeceb,
        'react-table-header-text': #bbc5cf,
        'react-table-text': #262632,
        'react-table-border': #f4f5f7,
        'scrollbar-background': #bbc5cf,
        'scrollbar-thumb': #778ba3,
        'scrollbar-thumb-hover': #485a70,
        'hint-color': #3986ac,
        'autofill-background': #acb9c9,
        'dashboard-background': #f9fafc,
        'dashboard-block-title': #a7b5c2,
        'dashboard-widget-title': #40404c,
        'dashboard-widget-block-title': #778ca1,
        'dashboard-button-buy': #e52800,
        'dashboard-table-header': #778ba3,
        'dashboard-symbol-color': #f2984a,
        'dashboard-symbol-background': #fce9e5,
        'dashboard-asset': #acb9c9,
        'dashboard-credit': #58b07e,
        'dashboard-deposit': #899aac,
        'dashboard-date': #9eadc0,
        'dashboard-transaction-success': #6dd130,
        'dashboard-transaction-pending': #f2984a,
        'dashboard-transaction-cancel': #18222b,
        'dashboard-payment-widget-title': #34343e,
        'dashboard-border': #f5f6f8,
        'dashboard-table-border': #edf1f4,
        'dashboard-payment-icon-background': #f8e4a5,
        'dashboard-payment-account-card-background': #f3994b,
        'dashboard-payment-footer': #33333f,
    ),
    'sea': (
        // Colors
        'primary': #1f3e57,
        'secondary': #27c6a8,
        'background': #b2d5f1,
        'background-gray': #bed6e6,
        'text-color': #262632,
        'text-secondary': #27c6a8,
        'text-profit': #6dd130,
        'text-loss': #172228,
        'button-background': #FFF,
        'button-text': #000,
        'caret': #7090a0,
        'filter-border': #5080a0,
        'range-border': #406080,
        'progress-success-background': #6dd130,
        'progress-info-background': #ffac2c,
        'progress-danger-background': #fe4c96,
        'table-text': #212529,
        'table-background': #82b9d9,
        'table-border': #6e9fbc,
        'table-search-text': #587394,
        'table-right-text': #5c799c,
        'table-currency-text': #262632,
        'table-row-inactive': #ffeceb,
        'react-table-header-text': #6792be,
        'react-table-text': #262632,
        'react-table-border': #6e9fbc,
        'scrollbar-background': #5b7a9b,
        'scrollbar-thumb': #778ba3,
        'scrollbar-thumb-hover': #485a70,
        'hint-color': #3986ac,
        'autofill-background': #6e849e,
        'dashboard-background': #c1dcec,
        'dashboard-block-title': #536e87,
        'dashboard-widget-title': #40404c,
        'dashboard-widget-block-title': #778ca1,
        'dashboard-button-buy': #e52800,
        'dashboard-table-header': #778ba3,
        'dashboard-symbol-color': #f2984a,
        'dashboard-symbol-background': #fce9e5,
        'dashboard-asset': #6e849e,
        'dashboard-credit': #58b07e,
        'dashboard-deposit': #67819d,
        'dashboard-date': #637fa4,
        'dashboard-transaction-success': #6dd130,
        'dashboard-transaction-pending': #f2984a,
        'dashboard-transaction-cancel': #18222b,
        'dashboard-payment-widget-title': #34343e,
        'dashboard-border': #6e9fbc,
        'dashboard-table-border': #6190ab,
        'dashboard-payment-icon-background': #f8e4a5,
        'dashboard-payment-account-card-background': #f3994b,
        'dashboard-payment-footer': #33333f,
    ),
    'spring': (
        // Colors
        'primary': #0b3e1b,
        'secondary': #27c6a8,
        'background': #B6FFCE,
        'background-gray': #9ddeb2,
        'text-color': #02250e,
        'text-secondary': #27c6a8,
        'text-profit': #6dd130,
        'text-loss': #172228,
        'button-background': #fff,
        'button-text': #000,
        'caret': #c4c4c4,
        'filter-border': #77787a,
        'range-border': #5d5d5d,
        'progress-success-background': #6dd130,
        'progress-info-background': #ffac2c,
        'progress-danger-background': #fe4c96,
        'table-text': #212529,
        'table-background': #B6FFCE,
        'table-border': #4f806c,
        'table-search-text': #4b6e40,
        'table-right-text': #4b6e40,
        'table-currency-text': #262632,
        'table-row-inactive': #ffeceb,
        'react-table-header-text': #659b62,
        'react-table-text': #262632,
        'react-table-border': #1e5b42,
        'scrollbar-background': #bbc5cf,
        'scrollbar-thumb': #778ba3,
        'scrollbar-thumb-hover': #485a70,

        'hint-color': #3986ac,
        'autofill-background': #acb9c9,
        'dashboard-background': #aaf1c2,
        'dashboard-block-title': #a7b5c2,
        'dashboard-widget-title': #224c2d,
        'dashboard-widget-block-title': #778ca1,
        'dashboard-button-buy': #e52800,
        'dashboard-table-header': #778ba3,
        'dashboard-symbol-color': #f2984a,
        'dashboard-symbol-background': #fce9e5,
        'dashboard-asset': #acb9c9,
        'dashboard-credit': #58b07e,
        'dashboard-deposit': #899aac,
        'dashboard-date': #9eadc0,
        'dashboard-transaction-success': #6dd130,
        'dashboard-transaction-pending': #f2984a,
        'dashboard-transaction-cancel': #18222b,
        'dashboard-payment-widget-title': #34343e,
        'dashboard-border': #1e5b42,
        'dashboard-table-border': #1e5b42,
        'dashboard-payment-icon-background': #f8e4a5,
        'dashboard-payment-account-card-background': #f3994b,
        'dashboard-payment-footer': #333f35,
    ),
    'butterfly': (
        // Colors
        'primary': #54122b,
        'secondary': #27c6a8,
        'background': #FFDBE9,
        'background-gray': #e7a0ae,
        'text-color': #2f2632,
        'text-secondary': #27c6a8,
        'text-profit': #6dd130,
        'text-loss': #172228,
        'button-background': #ffffff,
        'button-text': #000,
        'caret': #c8b5cd,
        'filter-border': #7a777a,
        'range-border': #6a5b6b,
        'progress-success-background': #6dd130,
        'progress-info-background': #ffac2c,
        'progress-danger-background': #fe4c96,
        'table-text': #272129,
        'table-background': #e2acc5,
        'table-border': #754f80,
        'table-search-text': #6e405e,
        'table-right-text': #6e4066,
        'table-currency-text': #322632,
        'table-row-inactive': #ffeceb,
        'react-table-header-text': #9b6292,
        'react-table-text': #2f2632,
        'react-table-border': #4f1e5b,
        'scrollbar-background': #cdbbcf,
        'scrollbar-thumb': #a077a3,
        'scrollbar-thumb-hover': #694870,

        'hint-color': #a639ac,
        'autofill-background': #c9acc5,
        'dashboard-background': #FFE6EE,
        'dashboard-block-title': #884a6c,
        'dashboard-widget-title': #4b224c,
        'dashboard-widget-block-title': #a077a1,
        'dashboard-button-buy': #e52800,
        'dashboard-table-header': #a177a3,
        'dashboard-symbol-color': #f2984a,
        'dashboard-symbol-background': #fce5fb,
        'dashboard-asset': #c9acc5,
        'dashboard-credit': #58b07e,
        'dashboard-deposit': #aa89ac,
        'dashboard-date': #c09ec0,
        'dashboard-transaction-success': #6dd130,
        'dashboard-transaction-pending': #f2984a,
        'dashboard-transaction-cancel': #29182b,
        'dashboard-payment-widget-title': #3e343d,
        'dashboard-border': #581e5b,
        'dashboard-table-border': #521e5b,
        'dashboard-payment-icon-background': #f8e4a5,
        'dashboard-payment-account-card-background': #f3994b,
        'dashboard-payment-footer': #3c333f,
    ),

    'cosmos': (
        // Colors
        'primary': #7994c7,
        'secondary': #27c6a8,
        'background': #1c2b46,
        'background-gray': #101747,
        'text-color': #d9d9cd,
        'text-secondary': #27c6a8,
        'text-profit': #6dd130,
        'text-loss': #e8ddd7,
        'button-text': #fff,
        'button-background': #2a3855,
        'caret': #3b3b3b,
        'filter-border': #888785,
        'range-border': #a2a2a2,
        'progress-success-background': #6dd130,
        'progress-info-background': #ffac2c,
        'progress-danger-background': #fe4c96,
        'table-text': #dedad6,
        'table-background': #030035,
        'table-border': #485063,
        'table-search-text': #4e73af,
        'table-right-text': #4e73af,
        'table-currency-text': #d9d9cd,
        'table-row-inactive': #ffeceb,
        'react-table-header-text': #426bb8,
        'react-table-text': #d9d9cd,
        'react-table-border': #0b0a08,
        'scrollbar-background': #bbc5cf,
        'scrollbar-thumb': #778ba3,
        'scrollbar-thumb-hover': #485a70,
        'hint-color': #3986ac,
        'autofill-background': #321051,
        'dashboard-background': #131e32,
        'dashboard-block-title': #426bb8,
        'dashboard-widget-title': #5e7fbb,
        'dashboard-widget-block-title': #426bb8,
        'dashboard-button-buy': #e52800,
        'dashboard-table-header': #778ba3,
        'dashboard-symbol-color': #f2984a,
        'dashboard-symbol-background': #fce9e5,
        'dashboard-asset': #363d53,
        'dashboard-credit': #58b07e,
        'dashboard-deposit': #535676,
        'dashboard-date': #3f4a61,
        'dashboard-transaction-success': #6dd130,
        'dashboard-transaction-pending': #f2984a,
        'dashboard-transaction-cancel': #e7ddd4,
        'dashboard-payment-widget-title': #cbcbc1,
        'dashboard-border': #67738f,
        'dashboard-table-border': #120e0b,
        'dashboard-payment-icon-background': #f8e4a5,
        'dashboard-payment-account-card-background': #f3994b,
        'dashboard-payment-footer': #c0c7cc,
    ),
    'forest': (
        // Colors
        'primary': #a9d6b0,
        'secondary': #27c6a8,
        'background': #2A4930,
        'background-gray': #132F13,
        'text-color': #ecfcef,
        'text-secondary': #27c6a8,
        'text-profit': #6dd130,
        'text-loss': #e8ddd7,
        'button-text': #fff,
        'button-background': #39563e,
        'caret': #243528,
        'filter-border': #4f6b55,
        'range-border': #6b876d,
        'progress-success-background': #6dd130,
        'progress-info-background': #ffac2c,
        'progress-danger-background': #fe4c96,
        'table-text': #dedad6,
        'table-background': #00350f,
        'table-border': #48634c,
        'table-search-text': #4eaf5d,
        'table-right-text': #4eaf59,
        'table-currency-text': #cdd9cf,
        'table-row-inactive': #ffeceb,
        'react-table-header-text': #42b85a,
        'react-table-text': #cdd9d0,
        'react-table-border': #080b08,
        'scrollbar-background': #bbcfc0,
        'scrollbar-thumb': #77a378,
        'scrollbar-thumb-hover': #48704f,
        'hint-color': #39ac4a,
        'autofill-background': #321051,
        'dashboard-background': #132F13,
        'dashboard-block-title': #42b869,
        'dashboard-widget-title': #5ebb72,
        'dashboard-widget-block-title': #42b84c,
        'dashboard-button-buy': #e52800,
        'dashboard-table-header': #77a387,
        'dashboard-symbol-color': #f2984a,
        'dashboard-symbol-background': #fce9e5,
        'dashboard-asset': #759981,
        'dashboard-credit': #58b07e,
        'dashboard-deposit': #19361c,
        'dashboard-date': #19361c,
        'dashboard-transaction-success': #6dd130,
        'dashboard-transaction-pending': #f2984a,
        'dashboard-transaction-cancel': #e7ddd4,
        'dashboard-payment-widget-title': #c1cbc2,
        'dashboard-border': #678f6e,
        'dashboard-table-border': #0b120c,
        'dashboard-payment-icon-background': #f8e4a5,
        'dashboard-payment-account-card-background': #f3994b,
        'dashboard-payment-footer': #c0c7cc,
    ),
    'sensual': (
        // Colors
        'primary': #afa6e8,
        'secondary': #27c6a8,
        'background': #311E99,
        'background-gray': #132F13,
        'text-color': #fbecfc,
        'text-secondary': #27c6a8,
        'text-profit': #6dd130,
        'text-loss': #e8ddd7,
        'button-text': #fff,
        'button-background': #321dad,
        'caret': #242535,
        'filter-border': #262145,
        'range-border': #282145,
        'progress-success-background': #6dd130,
        'progress-info-background': #ffac2c,
        'progress-danger-background': #fe4c96,
        'table-text': #d7d6de,
        'table-background': #080035,
        'table-border': #4a4863,
        'table-search-text': #796fff,
        'table-right-text': #8577ff,
        'table-currency-text': #cfcdd9,
        'table-row-inactive': #ffeceb,
        'react-table-header-text': #9388f4,
        'react-table-text': #cecdd9,
        'react-table-border': #08080b,
        'scrollbar-background': #bbbbcf,
        'scrollbar-thumb': #7b77a3,
        'scrollbar-thumb-hover': #4b4870,
        'hint-color': #6c51f3,
        'autofill-background': #141051,
        'dashboard-background': #20155F,
        'dashboard-block-title': #6452f1,
        'dashboard-widget-title': #968bf8,
        'dashboard-widget-block-title': #a4a1f0,
        'dashboard-button-buy': #e52800,
        'dashboard-table-header': #7b77a3,
        'dashboard-symbol-color': #f2984a,
        'dashboard-symbol-background': #fce9e5,
        'dashboard-asset': #a59af4,
        'dashboard-credit': #8177f4,
        'dashboard-deposit': #6e60e4,
        'dashboard-date': #918eff,
        'dashboard-transaction-success': #6dd130,
        'dashboard-transaction-pending': #f2984a,
        'dashboard-transaction-cancel': #e7ddd4,
        'dashboard-payment-widget-title': #c3c1cb,
        'dashboard-border': #68678f,
        'dashboard-table-border': #0b0b12,
        'dashboard-payment-icon-background': #f8e4a5,
        'dashboard-payment-account-card-background': #f3994b,
        'dashboard-payment-footer': #c0c0cc,
    ),
    'dark': (
        // Colors
        'primary': #3f51b5,
        'secondary': #27c6a8,
        'background': #000,
        'background-gray': #101010,
        'text-color': #d9d9cd,
        'text-secondary': #27c6a8,
        'text-profit': #6dd130,
        'text-loss': #e8ddd7,
        'button-text': #fff,
        'button-background': #1e202f,
        'caret': #3b3b3b,
        'filter-border': #888785,
        'range-border': #a2a2a2,
        'progress-success-background': #6dd130,
        'progress-info-background': #ffac2c,
        'progress-danger-background': #fe4c96,
        'table-text': #dedad6,
        'table-background': #000,
        'table-border': #090806,
        'table-search-text': #88745c,
        'table-right-text': #88745c,
        'table-currency-text': #d9d9cd,
        'table-row-inactive': #ffeceb,
        'react-table-header-text': #443a30,
        'react-table-text': #d9d9cd,
        'react-table-border': #0b0a08,
        'scrollbar-background': #bbc5cf,
        'scrollbar-thumb': #778ba3,
        'scrollbar-thumb-hover': #485a70,
        'hint-color': #3986ac,
        'autofill-background': #534636,
        'dashboard-background': #151513,
        'dashboard-block-title': #584a3d,
        'dashboard-widget-title': #bfbfb3,
        'dashboard-widget-block-title': #88735e,
        'dashboard-button-buy': #e52800,
        'dashboard-table-header': #778ba3,
        'dashboard-symbol-color': #f2984a,
        'dashboard-symbol-background': #fce9e5,
        'dashboard-asset': #534636,
        'dashboard-credit': #58b07e,
        'dashboard-deposit': #766553,
        'dashboard-date': #61523f,
        'dashboard-transaction-success': #6dd130,
        'dashboard-transaction-pending': #f2984a,
        'dashboard-transaction-cancel': #e7ddd4,
        'dashboard-payment-widget-title': #cbcbc1,
        'dashboard-border': #0a0907,
        'dashboard-table-border': #120e0b,
        'dashboard-payment-icon-background': #f8e4a5,
        'dashboard-payment-account-card-background': #f3994b,
        'dashboard-payment-footer': #ccccc0,
    ),
) !default;
