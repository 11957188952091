@import "../../assets/css/theme.scss";

.add-property-page {
  .MuiCard-root {
    overflow: visible;
  }

  .add-property-form {
    .block-1 {
      .autocomplete-form-label {
        // color: #3f424b;
        // font-size: 15px;
        // font-weight: 300;
        // margin: 20px 0px 10px 0;
        // display: block;
        position: relative;
        top: 8px;
        left: 0;
        background: #fff;
        max-width: 97px;
        padding-left: 5px;
        transform: scale(0.80);
      }

      .address-lable-info-icon {
        color: #2196f3;
        font-size: 20px;
        position: relative;
        top: 3px;
        left: 2px;
      }

      .autocomplete-input {
        padding: 0px;
        // font-size: 15px;
        // border-top: 0px;
        // border-left: 0px;
        // border-right: 0px;
        // transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        // border-bottom: 1px solid rgba(0, 0, 0, 0.42);
        // height: 47px;
        border-radius: 8px;
        padding: 18px 15px;
        font-size: 15px;
        border: 1px solid $input-border-color;
        outline: transparent;
        width: "100%";

        &::placeholder {
          // text-transform: lowercase;
          // font-size: small;
        }
      }

      .MuiOutlinedInput-input {
        padding: 18px 15px;
        font-size: 15px;
      }

      .editor-error {
        color: #f44336;
        font-size: 12px;
        text-align: left;
      }

      .form-label {
        color: #3f424b;
        font-size: 15px;
        font-weight: 300;
        margin: 0 0 10px 0;
        display: block;
      }

      .MuiOutlinedInput-input {
        padding: 18px 15px;
        font-size: 15px;
      }

      .default-card {
        overflow: auto;

        // overflow-x: scroll;
        .action-button {
          max-width: 30px;
          max-height: 30px;
          min-width: 30px;
          min-height: 30px;
        }
      }

      .forecasted-distribution-lable {
        font-weight: 500;
        font-size: 16px;
      }

      .legal-warnings-lable {
        font-weight: 500;
        font-size: 16px;
      }

      .location-image {
        cursor: pointer;
        height: 150px;
        width: 100%;
      }

      .location-image-button {
        margin-left: 15px;
      }

      .autocomplete-field {
        label {
          top: -10px;
        }
      }

      .tox-tinymce {
        max-height: 300px;
      }
    }

    .hide {
      display: none;
    }

    .block-3 {
      .upload-picture-file {
        display: none;
      }

      .floor-plan-image-file {
        display: none;
      }

      .floor-plan-image {
        cursor: pointer;
        height: 150px;
        width: 150px;
        border-radius: 6px;
      }

      .floor_plan_box {
        position: relative;

        .action-button {
          position: absolute;
          right: 8px;
          top: 8px;
          width: 24px;
          min-width: 24px;
          height: 24px;
          border-radius: 24px;
        }
      }

      .property-picture-item-container {
        padding: 10px;
        margin-top: 5px;

        .picture {
          cursor: pointer;
          height: 150px;
          width: auto;
          border-radius: 6px;
        }

        .action-button {
          max-width: 30px;
          max-height: 30px;
          min-width: 30px;
          min-height: 30px;
        }
      }
    }

    .block-4 {
      .custom-date-picker {
        .calendar {
          z-index: 99999;
        }
      }
    }

    .block-5 {
      .upload-doc-file {
        display: none;
      }

      .property-file-item-container {
        padding: 10px;
        margin-top: 5px;

        .file-icon-container {
          text-align: center;

          .file-icon {
            height: 50px;
          }

          .file-name {
            font-size: 10px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            color: grey;
          }
        }

        .action-button {
          max-width: 30px;
          max-height: 30px;
          min-width: 30px;
          min-height: 30px;
        }
      }
    }

    .action-buttons {
      margin-right: 20px;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      > * + * {
        margin-left: 8px;
      }
    }
  }

  .MuiStepIcon-text {
    @include themify-no-context {
      fill: $background;
    }
  }

  .MuiStepLabel-label.MuiStepLabel-completed,
  .MuiStepLabel-label.MuiStepLabel-active {
    @include themify-no-context {
      color: $text-color;
    }
  }

  .MuiStepIcon-root.MuiStepIcon-active {
    @include themify-no-context {
      .MuiStepIcon-text {
        fill: #fff;
      }
    }
  }
  .draft-button {
    background: #66b266;
    border-color: #66b266;
    span {
      color: #fff;
      text-transform: none;
    }
  }
  .draft-button:hover {
    background: #366336;
  }
}

.manage-property-page {
  .manage-property-filters {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 30px;

    &:last-of-type {
      margin-bottom: 0px;
      justify-content: space-between;

      .manage-property-filter {
        flex-grow: 0;
        button:hover {
          color: #fff;
        }
      }
    }

    .manage-property-filter {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      flex-grow: 1;

      &.big-filter {
        flex-grow: 2;

        .MuiOutlinedInput-notchedOutline {
          border-color: $input-border-color;
        }
      }

      .form-control {
        width: 100%;
        border: 1px solid $input-border-color;
        padding: 5px 10px;
        border-radius: 5px;
      }

      .form-control-title {
        & input {
          padding: 13px;
        }
      }
    }
  }

  .table {
    .MuiButton-root {
      border-color: rgba(0, 0, 0, 0.42);
    }
    .action-buttons {
      display: flex;
      justify-content: space-between;
      min-width: 400px;
      a {
        font-size: 0.7rem;
      }
      button {
        font-size: 0.7rem;
        color: white;
        border: none;
        cursor: pointer;
        border-radius: 5px;
        transition: background-color 0.3s ease;
      }
      /* Edit button */
      .edit {
        background-color: #238dc1; /* Darker blue */
      }

      .edit-disabled {
        background-color: #04567c;
        cursor: not-allowed;
      }

      .edit:hover {
        background-color: #1c6e94; /* Even darker blue for hover */
      }

      /* Promote button */
      .promote {
        background-color: #ff9933; /* Darker orange */
      }

      .promote:hover {
        background-color: #cc7a29; /* Even darker orange for hover */
      }

      /* Demote button */
      .demote {
        background-color: #cc6600; /* Even darker orange */
        .promoted-tooltip {
          color: #fff;
          height: 15px;
          width: 15px;
          margin-left: 5px;
        }
      }

      .demote:hover {
        background-color: #994c00; /* Darkest orange for hover */
      }

      /* Sold button */
      .sold {
        background-color: #e6b800; /* Darker yellow */
      }

      .sold:hover {
        background-color: #b38f00; /* Even darker yellow for hover */
      }

      /* Set-sold button */
      .set-sold {
        background-color: #cc9900; /* Darkest yellow */
      }

      .set-sold:hover {
        background-color: #996f00; /* Even darker yellow for hover */
      }

      /* Active button */
      .active {
        background-color: #66b266; /* Darker green */
      }

      .active:hover {
        background-color: #4d804d; /* Even darker green for hover */
      }

      /* Inactive button */
      .inactive {
        background-color: #4d804d; /* Darker green */
      }

      .inactive:hover {
        background-color: #366336; /* Even darker green for hover */
      }

      /* Delete button */
      .delete {
        background-color: #cc3333; /* Darker red */
      }

      .delete:hover {
        background-color: #992626; /* Even darker red for hover */
      }
    }
    th:last-child {
      min-width: 390px;
    }
  }
}

/* Upload */
.upload-mask {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
  background: rgba(0, 0, 0, 0.5);

  .upload-image-container {
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    max-width: 400px;
    max-height: 400px;
    background-color: #fff;
    color: #000;
    padding: 20px;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 5px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;

    h3 {
      font-size: 16px;
      font-weight: 500;
      margin: 0;
      padding: 0;
    }

    .upload-images {
      width: 150px;
      height: 150px;
      border-radius: 5px;
      overflow: hidden;
      background-color: #eee;
      position: relative;

      .upload-item {
        width: 150px;
        height: auto;
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: transform 0.3s ease;
      }

      .upload-item.next-upload {
        transform: translate(150%, -50%);
      }
      .upload-item.prev-upload {
        transform: translate(-150%, -50%);
      }
      .upload-item.current-upload {
        transform: translate(-50%, -50%);
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .MuiStepper-root {
    display: block !important;

    .MuiStepConnector-root {
      display: none;
    }
  }

  .MuiStep-horizontal {
    padding: 3px 0;
  }
}

@media screen and (max-width: 767px) {
  .react-date-picker__calendar {
    width: 240px !important;
  }

  .custom-date-picker .react-date-picker button {
    font-size: 13px;
    padding: 5px;
  }

  .react-calendar__navigation button {
    min-width: 36px !important;
  }

  .custom-date-picker {
    .react-date-picker {
      .react-calendar__year-view {
        .react-calendar__tile {
          padding: 15px 0px;
        }
      }
    }
  }
}

.table-scroll {
  overflow: scroll;
}


.custom-pagination {
  height: 80px;
  border-bottom: 0;
  .MuiToolbar-root {
    padding-left: 0;
    padding-right: 0;
  }
  &.MuiTableCell-root {
    border-bottom: 0;
  }
}
