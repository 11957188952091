.contactItem {
    display: grid;
    grid-template-columns: 70px 1fr;
}

.logo {
    width: 100%;
    text-align: left;
    padding: 0;
    margin: 0;
    line-height: 0;
    img {
        width: 100%;
        max-width: 300px;
    }
}