.popup {
    display: block;
    position: relative;
    overflow: hidden;
    height: 1px;
    opacity: 0;
    &.active {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 9999;
        opacity: 1;
        height: 100%;
        width: 100%;

        .box {
            transform: translate(-50%, -50%) scale(1);
            transition: transform 0.5s;
        }
    }

    .box {
        position: absolute;
        top: 50%;
        left: 50%;
        background-color: rgba(255, 255, 255, 0.3);
        // blur background
        backdrop-filter: blur(6px);
        border-radius: 10px;
        padding: 20px;
        width: 80%;
        max-width: 400px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
        text-align: center;
        transform: translate(-50%, -50%) scale(0);
        transition: transform 0.5s;

        h2 {
            font-size: 28px;
            margin-bottom: 20px;
        }
    }
}

.content {
    display: flex;
    flex-direction: column;
    gap: 15px;
}



.btn {
    padding: 10px 20px;
    border: none;
    border-radius: 20px;
    background-color: #2BAAEA;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 16px;

    &:hover {
        background-color: #0293db;
    }
}

.btnLink {
    text-decoration: none;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-size: 14px;
    font-weight: 600;
}