@import "../../assets/css/theme.scss";

.hero-banner {
  height: 600px;
  margin-top: -95px;
  background: url("./../../assets/images/main-bg-image-new.jpg");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;

  .filter-box .filter-content .action-footer .btn-delete .MuiButton-label {
    color: #2baaea;
  }

  .banner-title {
    font-size: 47px;
  }

  .hero-search {
    width: 100%;
    max-width: 780px;
    margin: 0 auto;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 70px;
  }

  .search-form {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 25px;
    flex-direction: row;
    flex-wrap: wrap;

    @include themify-no-context {
      background: transparent;
      color: $text-color;
    }

    padding: 15px 25px 30px;

    .search-form-rounded-container {
      position: absolute;
      background: $blue-primary-color;
      top: 0;
      width: 107px;
      height: 86px;
      display: flex;
      align-items: center;
      justify-content: center;
      left: 0;
      border-radius: 65% 0;
      img {
        width: 40px;
        height: 40px;
      }
    }

    .btn-filter {
      margin: 0 !important;
      border: none !important;
      height: 30px !important;
      justify-content: left !important;
      background-color: transparent;
      width: 100%;
    }
  }

  .search-title {
    font-size: 42px;
    color: #fff;
    text-align: center;
    font-weight: 600;
    margin: 0 0 30px 0;
    padding: 0;
    line-height: 1.2;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);

    small {
      display: block;
      font-size: 13px;
      font-weight: 400;
      padding: 5px 0 0 0;
    }

    span {
      display: inline-block;
      position: relative;

      &:last-child {
        transform: scale(6);
        opacity: 0;
        animation: zoomIn 0.8s forwards;
      }
    }

    @keyframes zoomIn {
      to {
        opacity: 1;
        transform: scale(1);
      }
    }
  }

  .hero-search-footer {
    display: block;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .btn-hero-search {
    background: #6987a3;
    color: #fff;
    padding: 10px 50px;
    border-radius: 30px;
    font-size: 20px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s;
    margin: 0;
    border: solid 1px #fff;
    z-index: 1;
  }

  .form-control {
    flex-basis: 30%;
    position: relative;
    z-index: 1;

    .MuiButton-label,
    .MuiSelect-selectMenu,
    .MuiSelect-select.Mui-disabled {
      color: #fff;
    }

    @include themify-no-context {
      border: 1px solid rgba(255, 255, 255, 0.8);
      background: rgba(255, 255, 255, 0.5);
      border-radius: 20px;
      backdrop-filter: blur(10px);
      color: #fff;
      height: 32px;
    }
    border-radius: 5px;
    padding: 3px 0;
    max-width: 240px;

    @media screen and (min-width: 617px) {
      & .double-width {
        max-width: 492px;
      }
    }

    .MuiSelect-selectMenu {
      padding: 5px 15px;
    }
  }

  @media screen and (max-width: 768px) {
    padding-top: 50px;

    .search-form {
      position: relative;
      margin: 60px auto 0;
      // transform: none;
      width: unset;

      .search-title {
        font-size: 20px;
      }
    }
  }
}

.homepage-blue-header-section {
  @include themify-no-context {
    background: $primary;
  }
  padding: 33px 0;
  h2 {
    font-size: 40px;
    line-height: 45px;
    text-align: center;
    color: #fff;
  }
}

@media screen and (max-width: 480px) {
  .homepage-blue-header-section h2 {
    font-size: 30px;
  }
}

.homepage-blue-gradient-header-section {
  background-color: transparent;
  background-image: linear-gradient(163deg, #0175b0 22%, #4abbf3 93%);
  color: #ffffff;
  font-size: 94px;
  font-weight: 900;
  padding-bottom: 30px;
  padding-top: 10px;
}

@media screen and (max-width: 768px) {
  .homepage-blue-gradient-header-section {
    font-size: 46px;
  }

  .hero-banner .hero-search {
    bottom: 20px;
  }
  .hero-banner .search-form {
    flex-direction: column;
    gap: 10px;
    .form-control {
      width: 100%;
    }
  }
}

.counter-container {
  max-width: 1440px;
  margin: 0 auto;
}

.counter-text {
  font-size: 20px;
  text-align: center;
  font-weight: 300;
}

.homepage-video-section {
  padding: 60px 0;
  & .video {
    margin: 0 auto;
    width: fit-content;
    text-align: center;
  }
}

@media screen and (max-width: 480px) {
  .homepage-video-section .video iframe {
    max-width: 80%;
    height: auto;
  }
}

.market-lising-section {
  margin: 40px 0 0;
  min-height: 200px;

  .property-slider {
    margin: 0;
  }

  .block-title {
    margin-bottom: 20px;
    text-transform: capitalize;
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 26px;
    max-width: 400px;
  }

  .block-title::after {
    content: "";
    margin-top: 10px;
    display: block;
    width: 96px;
    height: 24px;
    background: $blue-primary-color;
  }
  .properties-show-all-container {
    background: $light-blue-primary-color;
    padding: 40px 0;
    margin-top: 30px;
    .show-all-link {
      @include themify-no-context {
        border: 2px solid $primary;
      }
      text-transform: uppercase;
      font-weight: 800;
      font-size: 20px;
      line-height: 19px;
      padding: 10px 20px;
      color: #333333 !important;
    }
    .show-all-link:hover {
      @include themify-no-context {
        background: $primary;
        color: $background !important;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .block-title {
      font-size: 20px;
    }

    .property-slider {
      margin: 0 20px;
    }
  }
}

.search-widget-dropdown-container {
  .MuiList-root {
    background: #fff;
  }
}
