.contact-property {
    max-width: 500px;
}

.coctactformGroup {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;

   
}

.contact__form {
    input, textarea, .MuiOutlinedInput-root {
        border-radius: 3px !important;
    }

    fieldset {
        border-color: #ccc !important;
    }

    .MuiButton-outlined {
        width: 100% !important;
        border-radius: 3px !important;
    }
}