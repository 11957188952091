@import "./../../assets/css/theme";

.registration-hero-banner {
  margin-top: -100px;
  background: linear-gradient(to top, #3c4e55a8, #3c4e55a8),
    url("./../../assets/images/main-bg-image.jpg") no-repeat top center;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 250px;
  padding-bottom: 10px;
}

.registration-box {
  width: 100%;
  max-width: 500px;
  margin: 5em auto 10em;
  text-align: center;
  color: $primary-color;
  border-radius: 100px 16px 18px 18px;
  background-color: #ecf9ff;

  .form-rounded-container {
    position: relative;
    background: $blue-primary-color;
    top: 0;
    width: 107px;
    height: 86px;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    border-radius: 65% 0;
    img {
      width: 55px;
      height: 55px;
    }
  }

  .MuiButton-outlined:hover {
    background-color: #bdbdbd !important;
  }

  .btn-social-login {
    width: 100%;
    max-width: 300px;
    margin-top: 20px;
    margin-bottom: 30px;
  }

  .form {
    // padding: 10px 15px;
    .btn-submit {
      margin-top: 30px;
      margin-bottom: 20px;
      width: 100%;
      max-width: 300px;
    }
  }
}

.modal-success {
  color: $primary-color !important;
  padding: 30px 25px !important;

  .btn-login {
    margin-top: 30px;
  }
}

@media screen and (max-width: 480px) {
  .registration-hero-banner {
    margin-top: 0;
    padding-top: 20px;
  }
  .registration-box {
    margin-top: 0;
  }
}
