@import "theme";

@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

body {
  // font-family: "Open Sans", Arial, sans-serif !important;
  font-family: "Inter", Arial, sans-serif !important;
  font-size: 14px;
  margin: 0;
  @include themify-no-context {
    color: $text-color;
  }
}

p {
  margin: 15px 0;
}

.MuiLinearProgress-root {
  height: 7px !important;
  border-radius: 10px;
}

.MuiLinearProgress-barColorPrimary {
  background-color: $secondary-color !important;
}

.MuiLinearProgress-colorPrimary {
  background-color: #e0e0e0 !important;
}

.MuiLinearProgress-bar {
  -webkit-animation: progressbar 1.5s forwards;
  animation: progressbar 1.5s forwards;
}

.step-wizard-block {
  padding: 20px 25px;
  margin: 20px auto;

  .MuiInputLabel-formControl {
    font-size: 14px;
  }
  .MuiFormControl-root {
    margin: 7px 0;
  }
}

.custom-date-picker {
  background: #fff;
  border-radius: 5px;
  .react-date-picker {
    .react-date-picker__wrapper {
      border-radius: 5px;
      .react-date-picker__inputGroup {
        padding: 5px 15px;
        height: 30px;

        .react-date-picker__inputGroup__input {
          user-select: none;
        }
      }
    }

    button,
    abbr {
      font-size: 15px;
    }
    .react-calendar__year-view .react-calendar__tile,
    .react-calendar__decade-view .react-calendar__tile,
    .react-calendar__century-view .react-calendar__tile {
      padding: 15px 19px;
    }
  }
}

a {
  // @include themify-no-context {
  //   color: $text-secondary !important;
  // }
  color: #2baaea;
  cursor: pointer;
  text-decoration: none;
}

.investment-details a {
  color: #2baaea !important;
  font-weight: 800;
}

a.primary-link {
  @include themify-no-context {
    color: $button-text !important;
  }
}

h2.h2 {
  font-size: 24px;
  font-weight: 400;
  margin: 0;
}

.btn {
  @include themify-no-context {
    background-color: $background !important;
    color: $text-color !important;
  }
  padding: 5px 20px !important;
  text-transform: capitalize !important;
}

.btn.btn-filled-out {
  @include themify-no-context {
    background-color: $text-color !important;
    color: $background !important;
  }
  padding: 5px 20px !important;
  text-transform: capitalize !important;
}

.btn-primary {
  @include themify-no-context {
    background-color: $button-background !important;
    color: $primary !important;
  }
  padding: 5px 20px !important;
  text-transform: capitalize !important;
}

.btn-primary.btn-filled-out {
  @include themify-no-context {
    background-color: $primary !important;
    color: $button-background !important;
  }
  padding: 5px 20px !important;
  text-transform: capitalize !important;
}

.btn-primary.btn-filled-out.btn-search-form {
  background-color: #ffffff !important;
  color: #000 !important;
  font-weight: 800;
  font-size: 20px;
  text-transform: uppercase !important;
  line-height: 19px;
  transition: all 0.3s ease;
  border: 3.5px solid #2baaea;
  box-shadow: 5px 7px 10px #4abff740;
  padding: 15px 25px !important;
}

.btn-primary.btn-filled-out.btn-search-form:hover {
  background-color: #2baaea !important;
  color: #fff !important;
}

.btn-secondary {
  @include themify-no-context {
    background-color: $button-background !important;
    color: $secondary !important;
  }
  padding: 5px 20px !important;
  text-transform: capitalize !important;

  & .Mui-disabled {
    opacity: 0.5;
    @include themify-no-context {
      color: $button-text !important;
    }
  }
}

.btn-secondary.btn-filled-out {
  @include themify-no-context {
    background-color: $secondary !important;
    color: $button-background !important;
  }
  padding: 5px 20px !important;
  text-transform: capitalize !important;
}

.btn-outline-primary {
  @include themify-no-context {
    border-color: $button-text !important;
    color: $button-text !important;
  }
}

.btn-outline-secondary {
  @include themify-no-context {
    border-color: $text-secondary !important;
    color: $button-text !important;
  }
}

.btn-outline-primary {
  @include themify-no-context {
    border: 1px solid $button-text !important;
  }
}

.btn-width-300 {
  width: 100%;
  max-width: 300px;
}

.btn-modal-close {
  position: absolute !important;
  top: 15px;
  right: 0;
}

.text-left {
  text-align: left;
}

.text-style-1 {
  font-size: 20px;
  font-weight: 400;
  margin: 10px 0 0 !important;
}

.MuiList-root {
  @include themify-no-context {
    background-color: $background;
    // background: #ecf9ff;
    color: $text-color;
  }
}

.MuiPaper-root {
  @include themify-no-context {
    background-color: $background;
    color: $text-color;
    border-color: $table-border;
  }
}

.MuiFormLabel-root.Mui-focused {
  @include themify-no-context {
    color: $text-color !important;
  }
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  @include themify-no-context {
    border-color: $text-color !important;
  }
}

.MuiDrawer-paper {
  min-width: 260px;
}

.MuiCheckbox-colorSecondary.Mui-checked {
  @include themify-no-context {
    color: $button-text !important;
  }
}

.MuiRadio-colorSecondary.Mui-checked {
  @include themify-no-context {
    color: $button-text !important;
  }
}

.MuiSelect-select:focus {
  background-color: transparent !important;
}

.MuiLinearProgress-root {
  height: 7px !important;
  border-radius: 10px;
}

.MuiLinearProgress-barColorPrimary {
  @include themify-no-context {
    background-color: $text-secondary !important;
  }
}

.MuiLinearProgress-colorPrimary {
  @include themify-no-context {
    background-color: $background-gray !important;
  }
}

.MuiFormLabel-root,
.MuiInputLabel-outlined,
.MuiInputLabel-outlined.Mui-focused {
  @include themify-no-context {
    color: $button-text !important;
  }
}

.Mui-focused {
  .MuiOutlinedInput-notchedOutline {
    @include themify-no-context {
      border-color: $button-text !important;
    }
  }
}

.no-padding {
  padding: 0 !important;
}

.caret {
  width: 0;
  height: 0;
  display: inline-block;
  border: 6px solid transparent;
  top: 5px;
  position: relative;
  left: 5px;
}

.caret.down {
  @include themify-no-context {
    border-top-color: $caret;
  }
}

.caret.right {
  @include themify-no-context {
    border-left-color: $caret;
  }
}

.caret.up {
  @include themify-no-context {
    border-bottom-color: $caret;
  }
}

.caret.left {
  @include themify-no-context {
    border-right-color: $caret;
  }
}

.block {
  position: relative;
}

.btn-filter {
  // margin: 5px 5px 10px !important;
  // text-transform: initial !important;
  // @include themify-no-context {
  //   border: 1px solid $filter-border !important;
  // }
  // font-weight: 400 !important;
  // font-size: 16px !important;
}

.filter-box {
  z-index: 9999;

  .filter-content {
    padding: 20px;
    box-shadow: none;
    // @include themify-no-context {
    //   border: 1px solid $filter-border;
    //   background: $background;
    // }
    border-radius: 8px;
    background: #fff;
    // box-shadow: 5px 5px 15px #00000073;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 8px 12px 0px;
    border: none;
    margin-top: 5px;
    min-width: 200px;
    max-width: 400px;
    .popper-form-wrapper {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
    .MuiOutlinedInput-root {
      // border: 1px solid;
      // margin-top: 20px;
      .MuiOutlinedInput-notchedOutline {
        border-color: #D0D5DD;
      }
      .MuiInputBase-input {
        width: 100%;
      }
    }
    .btn-toggle-switch {
      float: right;
    }

    .filter-title {
      margin: 0;
      font-size: 1rem;
      font-weight: 500;
      color: #101828;
    }
    .txt-title {
      font-size: 16px;
      margin: 0;
    }

    .txt-content {
      font-size: 16px;
      margin: 20px 0 5px;
      width: 160px;
    }

    .range-slider {
      margin: 20px 0;

      .input-range {
        width: 100%;
        max-width: 150px;

        .input-range__label {
          display: none;
        }

        .input-range__track {
          height: 2px;
          @include themify-no-context {
            background: $button-text;
          }
        }

        .input-range__slider {
          @include themify-no-context {
            background: $range-border;
            border: 1px solid $range-border;
          }
        }

        .input-range__track--active {
          @include themify-no-context {
            background: $button-text;
          }
        }
      }
    }

    .range-value {
      display: flex;
      flex-wrap: wrap;
      // gap: 8px;
      justify-content: space-between;
      .key-figure-filter {
        width: 48%;
        margin-bottom: 8px;
        form {
          margin-top: 8px;
        }
        p {
          font-size: 14px;
          font-weight: 500;
          color: #475467;
        }
        .MuiFormLabel-root {
          font-size: 14px;
        }
        .MuiInputBase-root {
          font-size: 14px;
        }
      }
      p {
        font-size: 18px;
        @include themify-no-context {
          color: $button-text;
        }
        font-weight: 300;
      }

      span {
        padding: 0 5px;
      }
    }
    .form-control {
        width: 100%;
        // width: 190px;
        border: none !important;
        .MuiSelect-outlined.MuiSelect-outlined {
          padding-top: 8px;
          padding-bottom: 8px;
        }
    }
    .location-range-value {
      gap: 8px;
      p {
        margin: 0;
        font-size: 1rem;
        font-weight: 500;
        color: #101828;
      }
    }
    .location-filter {
      // width: 48%;
      .MuiSelect-outlined.MuiSelect-outlined {
        padding-top: 8px;
        padding-bottom: 8px;
      }
      .form-control {
        width: 100%;
        width: 190px;
        border: none;
      }
    }

    .MuiFormControl-root {
      margin: 4px 0;
    }

    .action-footer {
      margin-top: 12px;
      .MuiButtonBase-root {
        border-radius: 4px;
        width: 74px;
        height: 26px;
      }
      .MuiButtonBase-root:hover {
        opacity: 0.8;
      }
      .btn-delete {
        background: transparent;
        border: 0.5px solid #2baaea;
        color: #2baaea;
      }
      .btn-save {
        background: #2baaea;
        color: #fff;
        float: right;
      }
    }
  }
}

.silder-custom-navigation {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  bottom: 0;
  height: 50px;

  .btn-arrow {
    position: absolute;
    outline: 0;
    transition: all 0.5s;
    border-radius: 35px;
    z-index: 1000;
    border: 0;
    background: transparent;
    min-width: 43px;
    min-height: 43px;
    opacity: 1;
    cursor: pointer;
  }

  .btn-arrow::before {
    font-size: 20px;
    @include themify-no-context {
      color: $button-text;
    }
    display: block;
    font-family: revicons;
    text-align: center;
    z-index: 2;
    position: relative;
  }

  .btn-arrow.prev {
    left: -15px;
  }

  .btn-arrow.prev::before {
    content: "\e824";
  }

  .btn-arrow.next {
    right: -15px;
  }

  .btn-arrow.next::before {
    content: "\e825";
  }
}

.funding-progress.MuiLinearProgress-root {
  height: 4px !important;
  border-radius: 10px;
  margin-top: 10px;
}

.progress-success {
  .MuiLinearProgress-barColorPrimary {
    @include themify-no-context {
      background-color: $progress-success-background !important;
    }
  }
}

.progress-info {
  .MuiLinearProgress-barColorPrimary {
    @include themify-no-context {
      background-color: $progress-info-background !important;
    }
  }
}

.progress-danger {
  .MuiLinearProgress-barColorPrimary {
    @include themify-no-context {
      background-color: $progress-danger-background !important;
    }
  }
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  @include themify-no-context {
    color: $react-table-text;
  }
}
table {
  border-collapse: collapse;
}
.table thead th {
  vertical-align: center;
  @include themify-no-context {
    border-bottom: 2px solid $react-table-header-text;
  }
}
.table:not(.table-key-figures) td,
.table th {
  padding: 0.75rem;
  vertical-align: center;
  @include themify-no-context {
    border-top: 1px solid $react-table-header-text;
  }
}
.table.table-key-figures td {
  // display: flex;
}
.table-bordered td,
.table-bordered th {
  @include themify-no-context {
    border: 1px solid $react-table-header-text;
  }
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-bordered tbody tr.inactive-property {
  @include themify-no-context {
    background-color: $table-row-inactive;
  }
}

.table-bordered tbody tr.draft-property {
  @include themify-no-context {
    background-color: #f8e2c9;
  }
}

.draft-property {
  background-color: #f8e2c9;
}
.pending-escrow-property {
  background-color: rgb(220, 236, 236);
}

.pending-utrust-property {
  background-color: rgb(220, 236, 236);
}
.table-bordered tbody tr.pending-escrow-property {
  @include themify-no-context {
    background-color: rgb(220, 236, 236);
  }
}

.table-bordered tbody tr.pending-utrust-property {
  @include themify-no-context {
    background-color: rgb(220, 221, 236);
  }
}

.form-control {
  .MuiInputLabel-outlined.Mui-focused {
    @include themify-no-context {
      color: $text-color !important;
    }
  }

  .Mui-focused {
    .MuiOutlinedInput-notchedOutline {
      @include themify-no-context {
        border-color: $text-color !important;
      }
    }
  }
}

.table-widget {
  @include themify-no-context {
    background: $background;
  }
  padding: 15px 20px;
  position: relative;
  border-radius: 12px;

  .table-header {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 15px;
    padding-bottom: 15px;
    @include themify-no-context {
      border-bottom: 1px solid $table-border;
    }
    margin-bottom: 10px;

    .search-box {
      @include themify-no-context {
        color: $table-search-text;
      }
      width: 100%;

      input {
        font-size: 14px;
        text-transform: uppercase;
      }
    }

    .left-block {
      width: 50%;
      display: inline-block;
    }

    .right-block {
      @include themify-no-context {
        color: $table-right-text;
      }
      float: right;

      .MuiSvgIcon-root {
        position: relative;
        top: 5px;
      }

      .txt-currency-name {
        @include themify-no-context {
          color: $table-currency-text;
        }
        margin-left: 10px;
      }
    }
  }
}

.ReactTable {
  border: none !important;
  margin-bottom: 1rem;

  .rt-th {
    font-size: 15px;
    @include themify-no-context {
      color: $react-table-header-text;
    }
    border: none !important;
    text-align: left;
  }

  .rt-thead.-header {
    box-shadow: none !important;
  }

  .rt-td {
    @include themify-no-context {
      color: $react-table-text;
    }
    border-right: none !important;
    font-size: 16px;
    padding: 10px 10px !important;
  }

  .rt-tbody .rt-tr-group {
    @include themify-no-context {
      border-bottom: 2px solid $react-table-border;
    }
  }
}

.widget-block {
  @include themify-no-context {
    background: $background;
  }
  border-radius: 12px;
}

.txt-profit {
  font-weight: 500;
  @include themify-no-context {
    color: $text-profit;
  }

  &:before {
    content: "+";
  }

  // &:after {
  //   content: "%";
  // }
}

.txt-loss {
  font-weight: 500;
  @include themify-no-context {
    color: $text-loss;
  }

  // &:before {
  //   content: "- ";
  // }

  // &:after {
  //   content: "%";
  // }
}

.txt-amount-profit {
  font-weight: 500;
  @include themify-no-context {
    color: $text-profit;
  }

  &:before {
    content: "+ $";
  }
}

.txt-amount-loss {
  font-weight: 500;
  @include themify-no-context {
    color: $text-loss;
  }

  &:before {
    content: "- $ ";
  }
}

.text-right {
  text-align: right;
  margin: 0;
}

.no-margin {
  margin: 0;
}

.table-box-shadow {
  @include themify-no-context {
    -webkit-box-shadow: inset 3px -50px 14px -13px
      change-color($background, $alpha: 0.85);
    -moz-box-shadow: inset 3px -50px 14px -13px
      change-color($background, $alpha: 0.85);
    box-shadow: inset 3px -50px 14px -13px
      change-color($background, $alpha: 0.85);
  }
  height: 45px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border-radius: 0 0 12px 12px;
}

.custom-date-picker {
  position: absolute;
  right: 0;
  @include themify-no-context {
    background: $background;
  }
  z-index: 999;
  top: 20px;

  .react-date-picker__inputGroup {
    display: none !important;
  }

  button,
  abbr {
    font-size: 15px;
  }
  .react-calendar__year-view .react-calendar__tile,
  .react-calendar__decade-view .react-calendar__tile,
  .react-calendar__century-view .react-calendar__tile {
    padding: 15px 19px;
  }
}

.swal2-container {
  z-index: 9999 !important;
}

@media screen and (max-width: 768px) {
  .table-widget {
    .table-header {
      .left-block {
        width: 100%;
      }

      .right-block {
        float: none;
      }
    }
  }
}

@keyframes progressbar {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

.MuiInputBase-root,
.MuiInputBase-input {
  @include themify-no-context {
    color: $text-color;
  }
}

.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline,
.MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline,
.MuiFormControl-root .MuiOutlinedInput-notchedOutline {
  @include themify-no-context {
    border-color: $input-border-color;
  }
}

.dashboard-container {
  @include themify-no-context {
    background: $dashboard-background;
  }
  min-height: calc(100vh - 90px);
}
.no-padding {
  padding: 0 !important;
}
.dashboard-container .main-content {
  width: calc(100% - 263px - 50px);
  margin-left: 263px;
  margin-top: 70px;
  padding: 30px 25px;
  // background-color: #fff;
  // box-shadow: 2px 16px 19px 0px rgba(0, 0, 0, 0.09);
  border-radius: 12px;

  @media screen and (max-width: 959px) {
    width: calc(100% - 50px);
    margin-left: 0;
  }
}

.MuiButton-root {
  @include themify-no-context {
    background-color: $button-background;
    color: $button-text;
  }
}

.ReactTable .rt-noData {
  @include themify-no-context {
    background: change-color($background, $alpha: 0.85);
    color: $text-color;
  }
}

.react-tel-input .form-control:focus,
.react-tel-input .form-control {
  @include themify-no-context {
    background: $background;
    color: $text-color;
    border-color: $dashboard-payment-footer !important;
  }
}

.react-tel-input .country-list {
  @include themify-no-context {
    background: $background;
    color: $text-color;

    .country.highlight,
    .country.selected-flag,
    .country:hover {
      background-color: change-color($color: $text-color, $alpha: 0.85);
    }

    .country-name {
      color: $caret;
    }

    .dial-code {
      color: $text-color;
    }
  }
}

.MuiBackdrop-root {
  @include themify-no-context {
    background-color: change-color($color: $button-text, $alpha: 0.5);
  }
}

.MuiTypography-root,
.MuiSvgIcon-root,
.MuiFormControlLabel-root {
  @include themify-no-context {
    color: $text-color;
  }
}

.MuiTypography-root.error {
  @include themify-no-context {
    color: $dashboard-button-buy;
  }
}

.MuiInput-underline:before {
  @include themify-no-context {
    border-bottom-color: $text-color;
  }
}

.react-date-picker__inputGroup__input {
  @include themify-no-context {
    color: $text-color;
  }
}

.pac-target-input {
  @include themify-no-context {
    background-color: transparent;
    color: $text-color;
  }
}

.app-navbar-container {
  .MuiList-root {
    padding: 10px;
    padding-bottom: 6px;
  }
}

#register-login-form {
  .MuiFormLabel-root {
    color: $blue-primary-color !important;
  }
  .MuiOutlinedInput-notchedOutline {
    border-color: $blue-primary-color !important;
  }
  .MuiCheckbox-root {
    svg {
      color: $blue-primary-color;
    }
  }
  input {
    box-shadow: 0 0 0px 1000px #ecf9ff inset !important;
    -webkit-box-shadow: 0 0 0px 1000px #ecf9ff inset !important;
  }
}

.nav-link-toggle {
  & span {
    font-weight: 600;
    color: #101828 !important;
    & svg {
      font-weight: 600;
      color: #101828 !important;
    }
  }
}

.MuiButtonBase-root {
  
  &.MuiButton-containedSecondary {
    &.MuiButton-disableElevation {
      background-color: #2baaea;
      color: #fff;
      &:hover {
        background-color: darken($color: #2baaea, $amount: 10);
        color: #fff;
      }
    }
  }
  &.MuiButton-outlinedPrimary {
    &.MuiButton-disableElevation {
      border: 1px solid #2baaea;
      color: #2baaea;
      &:hover {
        background-color: #2baaea;
        color: #fff;
      }
    }
  }
  &.MuiButton-containedPrimary {
    &.MuiButton-disableElevation {
      background-color: #2baaea;
      color: #fff;
      &:hover {
        background-color: darken($color: #2baaea, $amount: 10);
        color: #fff;
      }
    }
  }
}

#action-menu {
  .MuiMenu-list {
    min-width: 200px;
  }
}