.hero-section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
  .hero-container {
    width: 100%;
    max-width: 1300px;
    background: url("../../assets/images/homepage.jpg");
    background-position: center;
    background-size: cover;
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-shadow: 0px 1px 3px 0px #1018281a;
    box-shadow: 0px 1px 2px 0px #101828;
    border-radius: 24px;
    padding-left: 16px;
    padding-right: 16px;
    h1 {
      color: #fff;
      font-size: 48px;
      font-weight: 600;
      line-height: 52px;
      margin-bottom: 16px;
      text-align: center;
      margin-top: 0;
      @media screen and (min-width: 991px) {
        font-size: 60px;
        line-height: 72px;
        margin-bottom: 32px;
        max-width: 644px;
      }
    }
    .search-hero-section {
      width: 100%;
      max-width: 900px;
      margin: 0 auto;
    }
    .custom-widget-container {
      .MuiList-root {
        background-color: #fff !important;
      }
    }
    .search-form {
      display: flex;
      flex-direction: row;
      padding: 12px 20px;
      align-items: center;
      background-color: #fff;
      border-radius: 8px;
      .form-control {
        flex: 1 1;
      }
      .MuiButton-root {
        border-radius: 0;
        color: #667085;
        font-size: 16px;
        text-transform: capitalize;
        min-width: 160px;
        border: 1px solid transparent;
        border-right-color: #d0d5dd;
        box-shadow: none;
      }
      .MuiButton-root:hover {
        background-color: #fff;
        color: #101828;
      }
      .MuiSelect-root {
        padding-left: 8px;
        color: #667085;
        font-size: 16px;
        text-transform: capitalize;
        // min-width: 160px;
        border: 1px solid transparent;
        border-right-color: #d0d5dd;
        box-shadow: none;
        background-color: #fff;
        line-height: 25px;
        text-align: center;
        &:focus {
          background-color: #fff !important;
        }
      }
      .mobile-filters-btn {
        display: flex;
        width: 100%;
        justify-content: left;
        border-right: none;
        @media screen and (min-width: 990px) {
          display: none;
        }
      }
      .last-search-select {
        .MuiSelect-root {
          border-right-color: transparent;
        }
      }
      .search-btn-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 8px;
        .search-btn {
          border-color: #0ba5ec;
          background-color: #0ba5ec;
          display: flex;
          align-items: center;
          height: 40px;
          width: 40px;
          border-radius: 12px;
          padding: 0;
        }
      }
      .filter-content-key-figures {
        min-width: 350px;
      }
      .action-footer {
        display: flex;
        justify-content: space-between;
        .MuiButtonBase-root {
          border-radius: 4px;
          min-width: 74px;
          width: 74px;
          height: 26px;
        }
        .btn-delete {
          background: transparent;
          border: 0.5px solid #2baaea;
          color: #2baaea;
        }
        .btn-save {
          background: #2baaea;
          color: #fff;
        }
      }
      .MuiInput-underline:before,
      .MuiInput-underline:after {
        border-bottom: none;
      }
    }
    // .search-button {
    //   background-color: #0BA5EC;
    //   min-width: auto;
    //   color: #fff;
    // }
    // .search-button:hover {
    //   background-color: #0BA5EC;
    // }
  }
}

.intro-section {
  margin-top: 48px;
  padding: 0 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (min-width: 991px) {
    margin-top: 96px;
  }
  .intro-container {
    width: 100%;
    max-width: 1300px;

    h2 {
      font-weight: 600;
      font-size: 36px;
      line-height: 44px;
      margin: 0 0 24px 0;
      color: #101828;
      text-align: center;
    }

    p {
      color: #101828;
      font-weight: 400;
      font-size: 14px;
      margin: 0;
      margin-bottom: 32px;
      text-align: center;
    }
    .intro-row {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 32px;

      @media screen and (min-width: 768px) {
        flex-direction: row;
        gap: 64px;
        p {
          color: #101828;
          font-size: 14px;
          line-height: 20px;
          margin: 0;
          font-weight: 400;
          text-align: center;
          max-width: 260px;
        }
      }
      .intro-title {
        margin: 0;
        font-size: 16px;
        color: #101828;
        font-weight: 600;
      }
      .intro-col {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.promoted-properties-section {
  margin-top: 48px;
  @media screen and (min-width: 991px) {
    margin-top: 96px;
  }
  h3 {
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    color: #101828;
  }
  .show-more-wrapper {
    text-align: center;
    p {
      margin-top: 64px;
      font-size: 16px;
      font-weight: 500;
      text-align: center;
    }
    .MuiButtonBase-root {
      background-color: #101828;
      color: #fff;
      text-transform: none;
      font-size: 16px;
      padding: 8px 16px;
      font-weight: 600;
    }
  }

  .testimonials-buttons-group {
    position: absolute;
    right: calc(50% - 48px);
    bottom: -55px;
    height: fit-content;
    @media screen and (min-width: 991px) {
      right: 20px;
      top: -81px;
      bottom: 0;
    }
  }
}

.counter-section {
  background-color: #f4f4f4;
  padding: 64px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 96px;
  .counter-row {
    width: 100%;
    max-width: 1300px;
    display: flex;
    gap: 32px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    @media screen and (min-width: 991px) {
      flex-direction: row;
    }
    .counter-col {
      flex: 1 1;
      display: flex;
      flex-direction: column;
      width: 100%;
      @media screen and (min-width: 660px) {
        width: 100%;
      }
    }
    .single-counter-wrapper {
      display: flex;
      gap: 32px;
      &:last-of-type {
        margin-top: 32px;
      }
      @media screen and (min-width: 600px) {
        gap: 32px;
      }
      p {
        margin: 0;
        font-weight: 600;
        font-size: 1rem;
      }

      .single-counter {
        flex: 1 1;
        display: flex;
        flex-direction: column;
        gap: 8px;
      }
    }
    .counter-title {
      font-size: 32px;
      margin: 0;
      b {
        font-weight: 600;
      }
      @media screen and (min-width: 991px) {
        font-size: 48px;
      }
    }
  }
}

.testimonial-section {
  // margin-top: 96px;
  background-color: #f4f4f4;
  padding: 96px 16px;
  display: flex;
  justify-content: center;
  align-items: center;

  .testimonial-container {
    position: relative;
    width: 100%;
    max-width: 1300px;
  }
  .testimonials-buttons-group {
    position: absolute;
    right: calc(50% - 48px);
    bottom: -80px;
    height: fit-content;
    @media screen and (min-width: 991px) {
      right: 0;
      top: 0;
    }
  }
  .testimonial-title {
    h3 {
      font-size: 32px;
      font-weight: 600;
      text-align: center;
      margin: 0;
    }
    margin-bottom: 64px;
  }
  .testimonial-card {
    max-width: 320px;
    padding: 32px 24px;
    box-shadow: 0px 1px 2px 0px #1018280f;
    box-shadow: 0px 1px 3px 0px #1018281a;
    border: 1px solid #eaecf0;
    background: #ffffff;
    border-radius: 12px;
    margin: 0 auto;

    .testimonial-content {
      margin-top: 30px;
      position: relative;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: #101828;
      margin-bottom: 14px;
      p {
        margin: 0;
      }
      .question-mark-top {
        position: absolute;
        top: -14px;
        left: 0;
      }
      .question-mark-bottom {
        position: absolute;
        bottom: -14px;
        right: 0;
      }
    }

    .testimonial-title {
      display: flex;
      flex-direction: column;
      .testimonial-job {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
        color: #475467;
      }
      .testimonial-name {
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
        color: #101828;
        text-align: center;
      }
    }
    .testimonial-image {
      text-align: center;
      margin-bottom: 16px;
    }
  }
}

.searching-for-agent-section {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 48px 16px;
  @media screen and (min-width: 991px) {
    padding: 96px 16px;
  }
  .searching-for-agent-container {
    width: 100%;
    max-width: 1200px;
    display: flex;
    gap: 64px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .searching-for-agent-img {
      img {
        max-width: 100%;
      }
    }
    @media screen and (min-width: 991px) {
      flex-direction: row;
    }

    .searching-for-agent-content {
      h4 {
        font-size: 32px;
        font-weight: 400;
        // max-width: 250px;
        line-height: 1.3;
        margin: 0;
        margin-bottom: 24px;
        b {
          font-weight: 600;
        }
        @media screen and (min-width: 991px) {
          max-width: 250px;
        }
      }
      p {
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        color: #475467;
        margin: 0;
        // max-width: 320px;
        @media screen and (min-width: 991px) {
          max-width: 320px;
        }
      }
    }
  }
}
.countries-section {
  // padding: 96px 16px;
  padding: 48px 16px 96px;
  display: flex;
  align-items: center;
  justify-content: center;
  .filters-wrapper {
    display: flex;
    gap: 16px;
    margin-bottom: 32px;
    flex-wrap: wrap;
    .MuiButton-root {
      text-transform: none;
      color: #475467;
      padding: 3px 8px;
      &.active-filter {
        border: 1px solid #0ba5ec;
        background: #0ba5ec1f;
      }
    }
  }
  .countries-container {
    width: 100%;
    max-width: 1300px;
    h4 {
      font-size: 32px;
      font-weight: 600;
      margin: 0;
      margin-bottom: 24px;
    }
    .countries-results-row {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      gap: 32px;
    }
    .country-card {
      // background: url('../../assets/images/countries/Austria.jpg');
      width: 190px;
      height: 190px;
      border-radius: 12px;
      background-position: center;
      background-size: cover;
      position: relative;
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      color: #fff;
      overflow: hidden;
      img {
        height: 190px;
      }
      span {
        position: absolute;
        z-index: 2;
        padding: 12px;
        font-size: 18px;
        font-weight: 600;
      }
      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        border-radius: 12px;
        background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0.2) 100%
          ),
          linear-gradient(
            180deg,
            rgba(0, 0, 0, 0) 38.84%,
            rgba(0, 0, 0, 0.5) 73.21%
          );
      }
    }
  }
}

.home-page-filters-modal {
  background-color: red;
  .MuiTab-root {
    padding: 6px 0;
  }
  .MuiTabs-root {
  }
}

.documents-section {
  margin-top: 32px;
  padding: 0 16px;
  .documents-wrapper {
    overflow-x: auto;
  }
  h3 {
    margin: 0;
    margin-bottom: 24px;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    text-align: left;
    color: #101828;
  }
  .styled-table {
    border-collapse: collapse;
    // margin: 25px 0;
    margin-top: 16px;
    font-size: 0.875rem;
    min-width: 400px;
    box-shadow: 0px 1px 3px 0px #1018281a;
    border-radius: 8px;
    // overflow: hidden;
  }

  .styled-table thead tr {
    background-color: #fff;
    text-align: left;
  }

  .styled-table th {
    padding: 12px 15px;
    font-weight: 500;
  }
  .styled-table td {
    padding: 12px 15px;
    font-weight: 400;
  }

  .styled-table tbody tr {
    border-bottom: 1px solid #dddddd;
  }

  .styled-table tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
  }

  .styled-table tbody tr:last-of-type {
    border-bottom: none;
  }
}

.mobile-filters {
  &.MuiDialogContent-root {
    padding-left: 16px;
    padding-right: 16px;
  }
  .custom-select {
    .MuiSelect-root {
      padding: 10px;
      box-shadow: 0px 1px 2px 0px #1018280d;
    }
    .MuiOutlinedInput-notchedOutline {
      border-color: #d0d5dd;
    }
    .Mui-disabled {
      border-color: #d0d5dd;
    }
  }
  .MuiInputBase-input {
    border-color: #d0d5dd;
  }
  .MuiOutlinedInput-notchedOutline {
    border-color: #d0d5dd !important;
    box-shadow: 0px 1px 2px 0px #1018280d;
  }

  .filter-title {
    margin: 16px 0;
    font-size: 1rem;
    font-weight: 500;
    color: #101828;
  }
  .key-figure-filter p {
    color: #262632;
    transition: all 0.3s ease;
    font-weight: 500;
    font-size: 0.875rem;
  }

  .reset-btn {
  }
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-outlined.show-btn {
  background-color: #0ba5ec;
  color: #fff;
  border: 1px solid #0ba5ec;
  text-transform: none;
}
button.MuiButtonBase-root.MuiButton-root.MuiButton-outlined.reset-btn {
  color: #0ba5ec;
  border: 1px solid #0ba5ec;
  text-transform: none;
}

.yachts-section {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 64px 16px;
  .yacht-section-container {
    width: 100%;
    max-width: 1300px;
    margin: 0 auto;
    .yacht-section-row {
      display: flex;
      flex-direction: column;
      // align-items: center;
      justify-content: space-between;
      gap: 32px;
      @media screen and (min-width: 991px) {
        flex-direction: row;
      }
      .content-col {
        display: flex;
        align-items: center;
      }
      .yacht-section-col {
        .yacht-section-content {
          width: 440px;
          text-align: left;
        } 
        h3 {
          font-size: 32px;
          margin: 0;
          b {
            font-weight: 600;
          }
          @media screen and (min-width: 991px) {
            font-size: 48px;
          }
        }
        p {
          font-size: 1.125rem;
        }
        img {
          max-width: 100%;
          border-radius: 24px;
        }
        .show-more-btn {
          &.MuiButtonBase-root {
            background-color: #101828 !important;
            color: #fff;
            text-transform: none;
            font-size: 16px;
            padding: 8px 16px;
            font-weight: 600;
          }
        }
      }
    }
  }
}