.country-widget {
    padding: 20px 0 40px 0;

    h3 {
        font-size: 20px;
        font-weight: 700;
        color: #262632;
        margin-bottom: 10px;
    }

    p {
        font-size: 14px;
        margin: 0;
        padding: 0;
    }

    .country-widget__list {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: 5px;
        margin-top: 40px;

        

        .country-widget__item {
            h4 {
                margin: 0;
                padding: 0;
                font-weight: 400;
                font-size: 14px;
                color: #333;
            }
        }
    }
}

// responsive
@media screen and (max-width: 768px) {
    .country-widget {
        padding: 20px 0 20px 0;

        h3 {
            font-size: 18px;
        }

        .country-widget__list {
            grid-template-columns: repeat(3, 1fr);
        }
    }
}

// responsive
@media screen and (max-width: 480px) {
    .country-widget {
        padding: 20px 0 20px 0;

        h3 {
            font-size: 16px;
        }

        .country-widget__list {
            grid-template-columns: repeat(2, 1fr);
        }
    }
}

// responsive
@media screen and (max-width: 370px) {
    .country-widget {
        padding: 20px 0 20px 0;

        h3 {
            font-size: 14px;
        }

        .country-widget__list {
            grid-template-columns: repeat(1, 1fr);
            
            .country-widget__item {
                text-align: center;
            }
        }
    }
}