.btn {
    border: none;
    background-color: #2BAAEA;
    color: #fff !important;
    padding: 10px 30px;
    font-size: 17px;
    font-weight: 500;
    margin: 40px 0 80px 0 !important;
    display: inline-block;
    text-decoration: none !important;
    cursor: pointer;
    border-radius: 3px;
    transition: all 0.2s ease-in-out;

    &:hover {
        background-color: #1983c0;
        color: #fff;
        box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.2);
    }

    &:active {
        transform: translateY(2px);
    }
}