@import './variables/colors';

// Colors
$primary: null;
$secondary: null;
$background: null;
$background-gray: null;
$text-color: null;
$text-secondary: null;
$text-profit: null;
$text-loss: null;
$button-text: null;
$button-background: #2baaea;
$caret: null;
$filter-border: null;
$range-border: null;
$progress-success-background: null;
$progress-info-background: null;
$progress-danger-background: null;
$table-text: null;
$table-background: null;
$table-border: null;
$table-search-text: null;
$table-right-text: null;
$table-currency-text: null;
$table-row-inactive: null;
$react-table-header-text: null;
$react-table-text: null;
$react-table-border: null;
$scrollbar-background: null;
$scrollbar-thumb: null;
$scrollbar-thumb-hover: null;
$hint-color: null;
$autofill-background: null;
$dashboard-background: null;
$dashboard-block-title: null;
$dashboard-widget-title: null;
$dashboard-widget-block-title: null;
$dashboard-button-buy: null;
$dashboard-table-header: null;
$dashboard-symbol-color: null;
$dashboard-symbol-background: null;
$dashboard-asset: null;
$dashboard-credit: null;
$dashboard-deposit: null;
$dashboard-date: null;
$dashboard-transaction-success: null;
$dashboard-transaction-pending: null;
$dashboard-transaction-cancel: null;
$dashboard-payment-widget-title: null;
$dashboard-border: null;
$dashboard-table-border: null;
$dashboard-payment-icon-background: null;
$dashboard-payment-account-card-background: null;
$dashboard-payment-footer: null;
$input-border-color: #d0d5dd;
@mixin setup-variables($theme-params) {
  // Set the theme variables with '!global'
  // Colors
  $primary: map-get($theme-params, 'primary') !global;
  $secondary: map-get($theme-params, 'secondary') !global;
  $background: map-get($theme-params, 'background') !global;
  $background-gray: map-get($theme-params, 'background-gray') !global;
  $text-color: map-get($theme-params, 'text-color') !global;
  $text-secondary: map-get($theme-params, 'text-secondary') !global;
  $text-profit: map-get($theme-params, 'text-profit') !global;
  $text-loss: map-get($theme-params, 'text-loss') !global;
  $button-text: map-get($theme-params, 'button-text') !global;
  $button-background: map-get($theme-params, 'button-background') !global;
  $caret: map-get($theme-params, 'caret') !global;
  $filter-border: map-get($theme-params, 'filter-border') !global;
  $range-border: map-get($theme-params, 'range-border') !global;
  $progress-success-background: map-get($theme-params, 'progress-success-background') !global;
  $progress-info-background: map-get($theme-params, 'progress-info-background') !global;
  $progress-danger-background: map-get($theme-params, 'progress-danger-background') !global;
  $table-text: map-get($theme-params, 'table-text') !global;
  $table-background: map-get($theme-params, 'table-background') !global;
  $table-border: map-get($theme-params, 'table-border') !global;
  $table-search-text: map-get($theme-params, 'table-search-text') !global;
  $table-right-text: map-get($theme-params, 'table-right-text') !global;
  $table-currency-text: map-get($theme-params, 'table-currency-text') !global;
  $table-row-inactive: map-get($theme-params, 'table-row-inactive') !global;
  $react-table-header-text: map-get($theme-params, 'react-table-header-text') !global;
  $react-table-text: map-get($theme-params, 'react-table-text') !global;
  $react-table-border : map-get($theme-params, 'react-table-border') !global;
  $scrollbar-background: map-get($theme-params, 'scrollbar-background') !global;
  $scrollbar-thumb: map-get($theme-params, 'scrollbar-thumb') !global;
  $scrollbar-thumb-hover: map-get($theme-params, 'scrollbar-thumb-hover') !global;
  $hint-color: map-get($theme-params, 'hint-color') !global;
  $autofill-background: map-get($theme-params, 'autofill-background') !global;
  $dashboard-background: map-get($theme-params, 'dashboard-background') !global;
  $dashboard-block-title: map-get($theme-params, 'dashboard-block-title') !global;
  $dashboard-widget-title: map-get($theme-params, 'dashboard-widget-title') !global;
  $dashboard-widget-block-title: map-get($theme-params, 'dashboard-widget-block-title') !global;
  $dashboard-button-buy: map-get($theme-params, 'dashboard-button-buy') !global;
  $dashboard-table-header: map-get($theme-params, 'dashboard-table-header') !global;
  $dashboard-symbol-color: map-get($theme-params, 'dashboard-symbol-color') !global;
  $dashboard-symbol-background: map-get($theme-params, 'dashboard-symbol-background') !global;
  $dashboard-asset: map-get($theme-params, 'dashboard-asset') !global;
  $dashboard-credit: map-get($theme-params, 'dashboard-credit') !global;
  $dashboard-deposit: map-get($theme-params, 'dashboard-deposit') !global;
  $dashboard-date: map-get($theme-params, 'dashboard-date') !global;
  $dashboard-transaction-success: map-get($theme-params, 'dashboard-transaction-success') !global;
  $dashboard-transaction-pending: map-get($theme-params, 'dashboard-transaction-pending') !global;
  $dashboard-transaction-cancel: map-get($theme-params, 'dashboard-transaction-cancel') !global;
  $dashboard-payment-widget-title: map-get($theme-params, 'dashboard-payment-widget-title') !global;
  $dashboard-border: map-get($theme-params, 'dashboard-border') !global;
  $dashboard-table-border: map-get($theme-params, 'dashboard-table-border') !global;
  $dashboard-payment-icon-background: map-get($theme-params, 'dashboard-payment-icon-background') !global;
  $dashboard-payment-account-card-background: map-get($theme-params, 'dashboard-payment-account-card-background') !global;
  $dashboard-payment-footer: map-get($theme-params, 'dashboard-payment-footer') !global;
}

@mixin unset-variables {
  // Unset the theme variables with `!global`
  // Colors
  $primary: null !global;
  $secondary: null !global;
  $background-gray: null !global;
  $text-color: null !global;
  $text-secondary: null !global;
  $text-profit: null !global;
  $text-loss: null !global;
  $button-text: null !global;
  $button-background: null !global;
  $caret: null !global;
  $filter-border: null !global;
  $range-border: null !global;
  $progress-success-background: null !global;
  $progress-info-background: null !global;
  $progress-danger-background: null !global;
  $table-text: null !global;
  $table-background: null !global;
  $table-border: null !global;
  $table-search-text: null !global;
  $table-right-text: null !global;
  $table-currency-text: null !global;
  $table-row-inactive: null !global;
  $react-table-header-text: null !global;
  $react-table-text: null !global;
  $react-table-border: null !global;
  $scrollbar-background: null !global;
  $scrollbar-thumb: null !global;
  $scrollbar-thumb-hover: null !global;
  $hint-color: null !global;
  $autofill-background: null !global;
  $dashboard-background: null !global;
  $dashboard-block-title: null !global;
  $dashboard-widget-title: null !global;
  $dashboard-widget-block-title: null !global;
  $dashboard-button-buy: null !global;
  $dashboard-table-header: null !global;
  $dashboard-symbol-color: null !global;
  $dashboard-symbol-background: null !global;
  $dashboard-asset: null !global;
  $dashboard-credit: null !global;
  $dashboard-deposit: null !global;
  $dashboard-date: null !global;
  $dashboard-transaction-success: null !global;
  $dashboard-transaction-pending: null !global;
  $dashboard-transaction-cancel: null !global;
  $dashboard-payment-widget-title: null !global;
  $dashboard-border: null !global;
  $dashboard-table-border: null !global;
  $dashboard-payment-icon-background: null !global;
  $dashboard-payment-account-card-background: null !global;
  $-payment-footer: null !global;
}

/// Themify mixin
/// @author Vernal Liu
/// @param {Map} $themes [$themes] - Map of themes to use
@mixin themify($themes: $themes) {
  // Iterate over the themes
  @each $theme, $theme-params in $themes {
    // Create a selector (e.g. `:host-context(.theme-unicorn) .media, .theme-unicorn .media`)
    // & .theme-#{$theme},
    // .theme-#{$theme} & ,
    :host-context(.theme-#{$theme}) & {
      // Setup variables according to theme
      @include setup-variables($theme-params);

      // Output user content
      @content;

      @include unset-variables;
    }
  }
}

@mixin themify-no-context($themes: $themes) {
  // Iterate over the themes
  @each $theme, $theme-params in $themes {
    // Create a selector (e.g. `:host-context(.theme-unicorn) .media, .theme-unicorn .media`)
    & .theme-#{$theme},
    .theme-#{$theme} & {
      // Setup variables according to theme
      @include setup-variables($theme-params);

      // Output user content
      @content;

      transition: all 0.3s ease;

      @include unset-variables;
    }
  }
}

// new dashboard variables
$new-gray:#EFEFEF;
$imo-blue: #2baaea;
$new-grey: #5D6D80;
$new-main-font: #101828;