@import "../../assets/css/theme.scss";

.admin-dashboard-content {
  @include themify-no-context {
    background: $dashboard-background;
  }

  .main-content {
    width: calc(100% - 263px - 50px);
    margin-left: 263px;
    margin-top: 70px;
    padding: 20px 25px 64px;

    @media screen and (max-width: 959px) {
      width: calc(100% - 50px);
      margin-left: 0;
    }

    h2.block-title {
      @include themify-no-context {
        color: $dashboard-block-title;
      }
    }
    p.block-title {
      @include themify-no-context {
        color: $dashboard-block-title;
      }
      font-size: 16px;
      font-weight: 500;
      margin: 15px 0;
    }

    .widget-title {
      @include themify-no-context {
        color: $dashboard-widget-title;
      }
      font-size: 14px;
      font-weight: 500;
      text-transform: uppercase;
      text-align: right;
      margin-right: 30px;
    }

    .btn-toggle-view {
      display: none;
      position: fixed;
      bottom: 20px;
      left: 50%;
      transform: translateX(-50%);
      @include themify-no-context {
        background: $background;
      }
      border-radius: 20px;
      z-index: 999;
    }
  }

  .btn-footer-action {
    position: fixed;
    bottom: 20px;
    right: 100px;
    z-index: 999991;
  }

  .chart-container {
    position: relative;
    height: 360px;
    width: calc(100% - 64px);
    padding: 16px 32px;
  }

  @media screen and (max-width: 960px) {
    .main-content {
      width: calc(100% - 30px);
      margin-left: 0;
      margin-top: 70px;
      padding: 20px 15px;
    }

    .btn-footer-action {
      display: none;
    }

    .btn-toggle-view {
      display: block;

      .MuiSvgIcon-root {
        width: 17px;
        height: 20px;
        position: relative;
        top: 5px;
      }
    }
  }
}

.downloads-table {
  margin-top: 10px;
}

.new-grid-box {
  box-shadow: 2px 16px 19px 0px rgba(0, 0, 0, 0.09);
  background: #fff;
  padding: 16px;
  border-radius: 12px;
  margin-bottom: 32px !important;
  &.MuiPaper-elevation1 {
    box-shadow: 2px 16px 19px 0px rgba(0, 0, 0, 0.09);
    background: #fff;
    padding: 16px;
    border-radius: 12px;
  }
}


.custom-table {
  box-shadow: none;
  .MuiTableHead-root {
    background-color: #F1F4F9;
    border-radius: 12px;
  }
}

.custom-table {
  // Give the container a border radius and box shadow.
  border-radius: 12px;
  // box-shadow: 2px 16px 19px 0px rgba(0, 0, 0, 0.09);
  overflow: hidden; // ensures the corners remain rounded

  // Target the <table> itself so we can make corners on the header cells.
  table {
    border-collapse: separate;
    border-spacing: 0; // remove default spacing in “separate” mode
  }

  // Style the table header background
  thead {
    background-color: #F1F4F9;

    // Round only the first and last cells in the header’s first row
    tr:first-child {
      th:first-child {
        border-top-left-radius: 12px;
      }
      th:last-child {
        border-top-right-radius: 12px;
      }
    }
  }
}