@import "./../../assets/css/theme";

.property-details-page {
  .MuiTypography-colorPrimary,
  .MuiButton-outlinedPrimary
   {
    @include themify-no-context {
      color: $primary;
    }
  }

  .section {
    margin-top: 40px;
  }

  .property-detail-box {
    @include themify-no-context {
      background-color: $background;
    }
    padding: 15px 0;

    .location-details {
      .marker {
        position: absolute;
        transform: translate(-50%, -100%);
        svg {
          width: 45px !important;
          height: 45px !important;
          fill: $secondary-color;
        }
      }
    }
  }

  .title-text {
    font-size: 28px;
    font-weight: 300;
    margin: 0 0 20px;
  }

  .content-text {
    font-size: 15px;
    font-weight: 300;
    word-break: break-all;
  }
}

.highlights-box {
  font-size: 15px;
  font-weight: 300;
  padding: 10px 0;

  .section:first-child {
    margin-top: auto;
  }
}

.property-title-box {
  .property-name {
    font-weight: bold;
    font-size: 42px;
    margin: 0;
  }

  .property-location {
    font-weight: 300;
    font-size: 18px;
    margin: 0;

    svg {
      color: $secondary-color;
    }

    span {
      position: relative;
      top: -4px;
    }
  }
}

.property-images-slider {
  .image {
    background-position: center;
    background-size: cover;
    height: 60vh;
    max-height: 600px;
    @include themify-no-context {
      background-color: $button-text;
    }
    background-repeat: no-repeat;
  }

  @media screen and (max-width: 768px) {
    .image {
      max-height: 250px;
    }
  }
}

.property-financial-information {
  width: 100%;
  max-width: 700px;
  margin: 0 0 20px;

  .financial-information-primary {
    .title-text {
      margin: 2em 0 1em;
    }

    .pledged-info {
      @include themify-no-context {
        color: $dashboard-widget-title;
      }
    }

    .financial-table {
      width: 100%;
      margin-top: 20px;
      text-align: center;

      td {
        /*border-right: 1px solid #77787a;*/
        width: 25%;
      }

      td:last-child {
        border-right: none;
      }

      .txt-title {
        font-size: 14px;
        @include themify-no-context {
          color: $dashboard-widget-title;
        }
        margin: 0 0 5px;
        font-weight: 300;
      }

      .txt-value {
        font-size: 22px;
        @include themify-no-context {
          color: $text-color;
        }
        margin: 0;
      }
    }
  }

  .financial-information-secondary {
    margin: 2em 0;
    padding: 20px 20px;
    border-radius: 7px;
    box-shadow: 0 4px 2px 0 rgba(0, 0, 0, 0.2);

    .value-block {
      .txt-value {
        margin: 15px 0px 0px;
        font-size: 25px;
        font-weight: 600;

        .txt-percentage {
          font-size: 20px;
          color: #27ae61;
          margin-left: 10px;
        }

        .txt-percentage-red {
          font-size: 20px;
          color: #27c6a8;
          margin-left: 10px;
        }
      }

      .txt-title {
        margin: 0px;
      }
    }
  }
}

.property-investment-box {
  max-width: 280px;
  @include themify-no-context {
    background-color: $background;
  }
  margin: 0 0 20px;

  .property-investment-box-direct {
    .value-block {
      width: 100%;
      .txt-title {
        margin: 0px;
        span {
          margin: 15px 0px 0px;
          font-size: 22px;
          color: #27c6a8 !important;
          font-weight: 600;
          text-transform: uppercase;
        }
      }
      .txt-value {
        margin: 15px 0px 0px;
        font-size: 25px;
        font-weight: 600;
        color: #27ae61;
      }

      .crypto-value {
        font-weight: 600;
        color: #27ae61;
      }

      .txt-information {
        margin: 8px auto;
        font-size: 16px;
      }
      .txt-action {
        margin: 15px auto;
        margin-bottom: 0px;

        button {
          padding: 4px 23px !important;
          font-weight: 600;
          font-size: 20px;
          border-radius: 6px;
          color: #fff !important;
          background-color: #27c6a8 !important;
          text-transform: uppercase !important;
          border: none;
          cursor: pointer;
          user-select: none;
        }
      }

      .mobile-btn-order {
        button {
          width: 100% !important;
          padding: 10px 31px;
          font-size: 15px;
          border-radius: 0px;
          color: #fff !important;
          background-color: #27c6a8 !important;
          text-transform: uppercase !important;
          border: none;
          cursor: pointer;
          user-select: none;
        }
      }
    }
  }

  .property-investment-box-primary {
    .table-block-1 {
      font-weight: 300;
      font-size: 14px;
      width: 100%;

      td {
        width: 50%;
        padding: 5px 0;
        text-align: center;

        .txt-value {
          font-size: 28px;

          span {
            font-size: 18px;
          }
        }

        .txt-title {
          font-size: 16px;
          margin-top: 5px;
        }
      }

      td:nth-child(1) {
        @include themify-no-context {
          border-right: 1px solid $filter-border;
        }
      }

      p {
        margin: 0;
      }
    }

    .table-block-2 {
      font-weight: 300;
      font-size: 14px;
      width: 100%;

      tr {
        td {
          width: 50%;
          @include themify-no-context {
            border-bottom: 1px solid $caret;
          }
          padding: 5px 0;
        }

        td:nth-child(2) {
          text-align: right;
        }
      }

      tr:last-child {
        td {
          border-bottom: none;
        }
      }
    }

    .btn-invest {
      margin: 0 auto;
      width: 100%;
      text-transform: none !important;
    }
  }

  .property-investment-box-secondary {
    margin: 1em 0;
    padding: 20px 20px;
    border-radius: 7px;
    box-shadow: 0 4px 2px 0 rgba(0, 0, 0, 0.2);

    .value-block {
      width: 100%;
      .txt-title {
        margin: 0px;
        span {
          margin: 15px 0px 0px;
          font-size: 22px;
          color: #27c6a8 !important;
          font-weight: 600;
          text-transform: uppercase;
        }
      }
      .txt-value {
        margin: 15px 0px 0px;
        font-size: 25px;
        font-weight: 600;
        color: #27ae61;
      }

      .txt-information {
        margin: 0px auto;
        font-size: 16px;
      }
      .txt-action {
        margin: 15px auto;
        margin-bottom: 0px;

        button {
          padding: 4px 23px !important;
          font-weight: 600;
          font-size: 20px;
          border-radius: 6px;
          color: #fff !important;
          background-color: #27c6a8 !important;
          text-transform: uppercase !important;
          border: none;
          cursor: pointer;
          user-select: none;
        }
      }

      .mobile-btn-order {
        button {
          width: 100% !important;
          padding: 10px 31px;
          font-size: 15px;
          border-radius: 0px;
          color: #fff !important;
          background-color: #27c6a8 !important;
          text-transform: uppercase !important;
          border: none;
          cursor: pointer;
          user-select: none;
        }
      }
    }
  }
}

.property-description-box {
  padding: 64px 28px;
  margin-left: -5px;

  .title-text {
    margin: 1.5em 0 1em;
  }

  .property-image {
    // border-radius: 12px;
    width: 100% !important;
    max-height: 200px;
    margin: 0 !important;
  }

  // .ReactGridGallery_tile-viewport {
  //   width: 100% !important;
  // }

  // .ReactGridGallery_tile {
  //   width: calc(100% / 4);
  //   background: transparent !important;
  // }

  .show-all-photos {
    position: relative;
    top: 10px;
    font-weight: 600;
    font-size: 16px;
  }
}

.property-key-figures-box {
  // margin: 4em 0 0em;

  .table {
    font-weight: 300;
    font-size: 14px;
    width: 100%;
    max-width: 350px;
    margin: 0 0 2em;

    tr {
      td {
        // width: 50%;
        padding: 5px 0;
      }

      td:nth-child(2) {
        text-align: right;
      }
    }
  }

  .card-block {
    font-weight: 300;
    font-size: 14px;

    .block-title {
      font-weight: 600;
      font-size: 16px;
      margin: 0;
    }

    .block-content {
    }
  }

  .floor-plan-img {
    // margin: 0 auto;
    max-width: 300px;
    border-radius: 12px;
  }

  .modal-container {
    z-index: 99;
  }
}

.investment-details {
  .distribution-yield-block {
    font-size: 16px;
  }

  .financial-detail-box {
    width: 100%;
    max-width: 430px;
    margin: 0 auto;
    font-size: 16px;
    margin: 3em auto 3em;
  }

  .table {
    &:not(.table-document) {
      td:first-child {
        text-align: left;
      }

      td:last-child {
        text-align: right;
      }
    }
  }

  .table-document {
    text-align: center;
  }

  .list-item {
    td {
      padding-left: 20px;
      font-weight: 300;
    }
  }

  .download-block {
    font-size: 16px;
    font-weight: 300;
    text-align: center;
    margin: 1em 4em 0px 0em;

    svg {
      fill: #27c6a8;
    }

    .doc-name {
      position: relative;
      top: -7px;
      @include themify-no-context {
        color: $button-text;
      }
      text-transform: uppercase;
      font-weight: 500;
      font-size: 15px;
    }
  }

  .txt-title {
    margin: 10px 0px;
    font-size: 28px;
    font-weight: 300;
  }

}

.purchase-button:hover {
  background-color: #2BAAEA !important;
}
.purchase-button:hover .MuiTypography-subtitle1 {
  color: #fff !important;
}
.purchase-button.Mui-disabled {
  border: 3.5px solid gray !important;
  box-shadow: 0px 0px 0px gray !important;
}
.purchase-button.Mui-disabled .MuiTypography-subtitle1 {
  color: gray !important;
}

.partners-details {
  .partner-list-root {
    @include themify-no-context {
      border: 1px solid $caret;
    }
    margin: 15px 0;
    border-radius: 12px;

    p {
      &:first-child {
        margin: 0px;
      }
      margin: 0 0 5px;
    }

    .list-item-container {
      .list-item {
        .MuiAvatar-root {
          img {
            object-fit: contain;
          }
        }

        .text-primary {
          @include themify-no-context {
            color: $text-color;
          }
          font-size: 1rem;
          font-weight: 400;
          line-height: 1.5;
          letter-spacing: 0.00938em;
        }

        .text-secondary {
          @include themify-no-context {
            color: $text-loss;
          }
          font-size: 0.875rem;
          font-weight: 400;
          line-height: 1.43;
          letter-spacing: 0.01071em;
        }

        .list-item-divider {
          border: none;
          height: 1px;
          margin: 20px 0px 0px 0px;
          flex-shrink: 0;
          @include themify-no-context {
            background-color: $text-loss;
          }
        }
      }

      &:last-child {
        .list-item {
          .list-item-divider {
            visibility: hidden;
          }
        }
      }
    }
  }
}

.location-details {
  // margin: 30px 0px;
  padding: 64px 28px;

  .txt-title {
    margin: 10px 0px;
    font-size: 28px;
    font-weight: 300;
  }

  img {
    max-width: 100%;
    border-radius: 12px;
  }

  .location-image {
    height: 300px;
    width: 100%;
  }
}

.invetment-footer {
  @include themify-no-context {
    border-top: 1px solid $caret;
    background: $table-border;
  }
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  z-index: 9999;
  height: 30px;
  padding: 20px 0px;

  .footer-logo {
    width: 200px;
    margin-left: 15px;
  }

  .property-title {
    font-size: 15px;
    text-align: center;
    margin: 3px 0px;
  }

  .property-share-value {
    font-weight: 500;
    font-size: 16px;
    padding-right: 20px;
  }

  .btn-secondary {
    margin-right: 15px;
    text-transform: none !important;
  }
}

@media screen and (max-width: 768px) {
  .property-details-page {
    .mobile-btn-invest,
    .mobile-btn-order {
      position: fixed;
      bottom: 0;
      width: 100%;
      left: 0;
      z-index: 9999;

      .btn-invest {
        border-radius: 0;
      }

      .btn-order {
        border-radius: 0;
      }
    }

    .invetment-footer {
      display: none;
    }

    .ReactGridGallery_tile {
      width: 100%;
    }

    .property-detail-box {
      padding: 15px 10px;

      .property-investment-detail {
        padding: 15px 0;
        margin: 0 auto;
      }

      .property-financial-information {
        .financial-table {
          td {
            width: calc(calc(100% / 2) - 1px);
            float: left;
            padding: 10px 0;
          }

          td:nth-child(2) {
            border-right: none;
          }

          td:nth-child(3),
          td:nth-child(4) {
            @include themify-no-context {
              border-top: 1px solid $filter-border;
            }
          }
        }
      }
    }
  }
}


#lightboxBackdrop {
  img {
    border-radius: 12px;
  }

  div[style*="background-image"] {
    border-radius: 8px;
  }
}
