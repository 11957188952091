.add-partner-page {
  .partner-form {
    .tab-panel {
      margin-top: 10px;

      .form-label {
        color: #3f424b;
        font-size: 15px;
        font-weight: 300;
        margin: 0 0 10px 0;
        display: block;
      }

      .MuiOutlinedInput-input {
        padding: 18px 15px;
        font-size: 15px;
      }
    }
  }
}

.edit-partner-page {
  .partner-form {
    .tab-panel {
      margin-top: 10px;

      .form-label {
        color: #3f424b;
        font-size: 15px;
        font-weight: 300;
        margin: 0 0 10px 0;
        display: block;
      }

      .MuiOutlinedInput-input {
        padding: 18px 15px;
        font-size: 15px;
      }
    }
  }
}

.manage-partner-page {
  .table {
    .partner-logo {
      height: 70px;
      width: 70px;
      border-radius: 6px;
      object-fit: cover;
    }

    .MuiButton-root {
      border-color:rgba(0, 0, 0, 0.42);
    }

    tr {
      td:last-child {
        width: 140px;
        vertical-align: middle;
      }
    }
  }
}
