@import "./../../assets/css/theme";

.footer-block {
  position: fixed;
  bottom: 0;
  width: 100%;
  @include themify-no-context {
    background: $background;
  }
  z-index: 99999;
}

.search-page {
  .listing-container {
    width: calc(100% + 40px);
  }

  .btn-toggle-view {
    display: none;
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    @include themify-no-context {
      background: $background;
    }
    border-radius: 20px;
    z-index: 999;
  }

  .btn-footer-action {
    position: fixed;
    bottom: 20px;
    right: 100px;
    z-index: 999991;
    border-radius: 4px;
    background: #29aaea;
    color: #fff;
    svg {
      color: #fff;
    }
  }

  .form-control {
    width: 100%;
    max-width: 240px;
    @include themify-no-context {
      border: 1px solid $range-border;
    }
    padding: 3px 15px;
    border-width: 0 0 1px 0 !important;

    @media screen and (min-width: 617px) {
      & .double-width {
        max-width: 492px;
      }

      width: 200px;
    }
  }

  @media screen and (max-width: 599px) {
    .btn-footer-action {
      display: none;
    }

    .btn-toggle-view {
      display: block;

      .MuiSvgIcon-root {
        width: 17px;
        height: 20px;
        position: relative;
        top: 5px;
      }
    }
  }
}

.search-lising-section {
  margin-top: 25px;

  .block-title {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 20px;

    .remove-partner-btn {
      margin-left: 20px;
    }
  }

  @media screen and (max-width: 768px) {
    .block-title {
      font-size: 20px;
    }
  }
}

.search-bar-block {
  // padding-top: 5px;
  width: 100%;
  @include themify-no-context {
    background: $background;
  }

  .search-form {
    padding: 16px 0;
    display: flex;
    flex-wrap: wrap;
    gap: 12px;

    // @media screen and (min-width: 768px) {

    // }

    @include themify-no-context {
      border-bottom: 1px solid $caret;
    }

    .btn-filter {
      text-transform: none;
      border: 1px solid #d0d5dd;
      box-shadow: 0px 1px 2px 0px #1018280d;
      border-radius: 8px;
      background-color: #fff;
      font-size: 1rem;
      font-weight: 400;
      color: #667085;
      // width: 175px;
      // background: #2aaaea;
      // color: #fff;
      // border: 0.5px solid #2baaea !important;
      // box-shadow: 5px 5px 10px 5px #0000000d;
      // border-radius: 4px;
    }
  }

  @media screen and (max-width: 768px) {
    .search-form {
      @include themify-no-context {
        border-bottom: 1px solid $caret;
      }

      .btn-filter {
        width: 46%;
        font-size: 12px !important;
      }
    }
  }
}

.search-bar {
  position: sticky;
  top: 77px;
  z-index: 99998;
  @include themify-no-context {
    background: $background;
  }
  // padding-top: 1;

  @media screen and (max-width: 960px) {
    top: 65px;
  }
  @media screen and (max-width: 768px) {
    position: unset;
  }
}

.fetch-loader {
  margin: 32px;
}

.infinite-scroll-component {
  overflow: initial !important;
}
