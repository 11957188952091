// @import "../../assets/css/theme.scss";

// .footer {
//     // padding-top: 24px;
//     padding: 20px 0 40px;
//     background-color: #3C4E55;
//     @include themify-no-context {
//         border-top: 1px solid $caret;
//     }
//     .logo-white-footer-container{
//         width: 180px;
//         height: 43px;
//         background-size: 100% 100%;
//         background-repeat: no-repeat;
//         margin-bottom: 10px;
//     }
//     .footer-grid-container{
//         margin-left: 40px;
//     }
//     a {
//         color: #fff !important;
//         font-weight: 700;
//         font-size: 15px;
//         line-height: 26px;
//       }
//     a:hover{
//         opacity: .7;
//     }
//     .widget-title {
//         margin-top: 30px;
//         @include themify-no-context {
//             color: $text-color;
//         }
//         font-size: 16px;
//         font-weight: bold;
//     }

//     .social-links {
//         list-style: none;
//         margin: 30px 0 10px;
//         padding: 0;

//         li {
//             cursor: pointer;
//             @include themify-no-context {
//                 color: $text-color;
//             }
//             margin: 0 5px 0 0;
//             display: inline-block;
//         }
//     }

//     .footer-links {
//         list-style: none;
//         margin: 0;
//         padding: 0;

//         li {
//             cursor: pointer;
//             @include themify-no-context {
//                 color: $text-color;
//             }
//             margin: 0 0 5px;
//             font-size: 16px
//         }
//     }

//     .footer-bottom {
//         margin-top: 15px;
//         @include themify-no-context {
//             border-top: 1px solid $caret;
//             color: $white-color;
//         }
//         font-size: 13px;
//     }
// }

.footer-section {
    padding: 96px 16px 16px 16px;
    background-color: #f4f4f4;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .footer-row {
        display: flex;
        flex-direction: column;
        gap: 16px;
        justify-content: space-between;
        @media screen and (min-width: 900px) {
            flex-direction: row;
        }
    }
    .footer-container {
        width: 100%;
        max-width: 1300px;
        border-bottom: 1px solid #101828;
        padding-bottom: 32px;
    }
    .footer-menu-col {
        display: flex;
        flex-direction: column;
    }
    .footer-logo-col {
        max-width: 420px;
        p {
            margin: 0;
            margin-top: 20px;
            line-height: 24px;
            color: #475467;
            font-size: 16px;
            font-weight: 400;
        }
    }
    .footer-menu-col {
        display: flex;
        flex-direction: column;
        gap: 8px;
        .footer-title {
            font-weight: 500;
            color: #101828;
            font-size: 18px;
        }
        a {
            color: #475467;
            font-weight: 400;
            font-size: 16px;
            &:hover {
                color: #101828;
                text-decoration: underline;
            }
        }
    }
    .footer-copyright {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        max-width: 1300px;
        margin-top: 16px;

        span {
            font-size: 16px;
            font-weight: 400;
            color: #475467;
        }
    }
}