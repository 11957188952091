@import "../../assets/css/theme.scss";

.manage-user-page {
  .table {
    .center {
      text-align: center;
      a {
        font-size: 0.7rem;
      }
      button {
        font-size: 0.7rem;
      }
      .action-buttons {
        display: flex;

        button {
          color: white;
        }
        .edit {
          background-color: #238dc1; /* Darker blue */
        }
        .edit:hover {
          background-color: #1c6e94; /* Even darker blue for hover */
        }
        .promote {
          background-color: #ff9933; /* Darker orange */
        }
        .promote:hover {
          background-color: #cc7a29; /* Even darker orange for hover */
        }
        .demote {
          background-color: #cc6600; /* Even darker orange */
        }
        .demote:hover {
          background-color: #994c00; /* Darkest orange for hover */
        }
        .active {
          background-color: #66b266; /* Darker green */
        }
        .active:hover {
          background-color: #4d804d; /* Even darker green for hover */
        }
        .sold {
          background-color: #e6b800; /* Darker yellow */
        }
        .sold:hover {
          background-color: #b38f00; /* Even darker yellow for hover */
        }
        .delete {
          background-color: #cc3333; /* Darker red */
        }
        .delete:hover {
          background-color: #992626; /* Even darker red for hover */
        }
      }
    }

    .MuiButton-root {
      border-color: rgba(0, 0, 0, 0.42);
    }

    tr {
      td:last-child {
        width: 100px;
        vertical-align: middle;

        > * {
          width: 100%;
          > button {
            width: 100%;
          }
        }
      }
    }
    th:last-child {
      min-width: 470px;
    }
  }
}

.edit-user-page {
  .default-form {
    .form-label {
      @include themify-no-context {
        color: $dashboard-widget-title;
      }
      font-size: 15px;
      font-weight: 300;
      margin: 0 0 10px 0;
      display: block;
    }

    .MuiOutlinedInput-input {
      padding: 18px 15px;
      font-size: 15px;
    }
  }

  .new-form {
    .MuiFormControl-root {
      max-width: 360px;
      width: 100%;
    }

    .password-label {
      display: flex;
      align-items: center;
      justify-content: space-between;
      align-content: center;
      width: 100%;
      max-width: 360px;
    }

    .MuiButton-text {
      background-color: transparent;
    }
  }

  h3 {
    padding-left: 16px;
  }

  .MuiCard-root {
    margin-bottom: 48px;
  }

  .MuiGrid-root.label {
    font-weight: bold;
    align-self: center;
  }

  .danger {
    .MuiGrid-root.label {
      color: red;
    }

    & .MuiButton-root {
      color: white;
      background-color: red;
    }
  }

  h3.danger {
    color: red;
  }
}
